import antdAR from "antd/lib/locale-provider/ar_EG";

const esArLang = {
  name: 'Arabic',
  antdLocale: antdAR,
  i18nLocale: 'es-AR',
  momentLocale: 'en-us',
  direction: 'rtl',
  icon: 'sa'
};
export default esArLang;
