import ApiService from "../util/api.service";
import { SERVICE_CONFIG_URLS } from "./config";

const AuthService = {
    login,
    signup,
    forgetPassword,
    emailVerification,
    resetPassword,
    resendVerificationEmail,
    removeSuspension
};

async function login(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.AUTH.LOGIN, payload);
    return response;
}

async function forgetPassword(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.AUTH.FORGET_PASSWORD, payload);
    return response;
}

async function signup(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.AUTH.SIGN_UP, payload);
    return response;
}

async function emailVerification(payload) {
    const response = await ApiService.patch(SERVICE_CONFIG_URLS.AUTH.EMAIL_VERIFICATION, payload);
    return response;
}

async function resetPassword(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.AUTH.RESET_PASSWORD, payload);
    return response;
}

async function removeSuspension(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.AUTH.REMOVE_SUSPENSION, payload);
    return response;
}

async function resendVerificationEmail(payload) {
    const response = await ApiService.patch(
        SERVICE_CONFIG_URLS.AUTH.RESEND_VERIFICATION_EMAIL,
        payload
    );
    return response;
}

export default AuthService;
