import ApiService from "../util/api.service";
import { SERVICE_CONFIG_URLS } from "./config";

const CustomerService = {
    getCustomerVehicleListing,
    getCustomers,
    addVehicle,
    getCustomer,
    getCustomerVehicleDetail,
    getCustomerProfileServices,
    verifyOtp,
    resendOtp,
    getTicketDetails,
    verifyImportConsent,
    getCustomerProfileCards,
};

async function getCustomerVehicleListing(payload, id) {
    const url = `${SERVICE_CONFIG_URLS.CUSTOMERS.CONTROLLER_NAME}/${id}/vehicles`;
    const response = await ApiService.post(url, payload);
    return response;
}

async function getCustomers(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.USER.CUSTOMERS, payload);
    return response;
}

async function addVehicle(payload, id) {
    const url = `${SERVICE_CONFIG_URLS.CUSTOMERS.CONTROLLER_NAME}/${id}/vehicles`;
    const response = await ApiService.put(url, payload);
    return response;
}

async function getCustomer(id) {
    const url = `${SERVICE_CONFIG_URLS.CUSTOMERS.CONTROLLER_NAME}/${id}`;
    const response = await ApiService.get(url);
    return response;
}

async function getCustomerVehicleDetail(customerId, vehicleId) {
    const url = `${SERVICE_CONFIG_URLS.CUSTOMERS.CONTROLLER_NAME}/${customerId}/vehicles/${vehicleId}`;
    const response = await ApiService.get(url);
    return response;
}

async function getCustomerProfileServices(customerId, payload) {
    const url = `${SERVICE_CONFIG_URLS.CUSTOMERS.CONTROLLER_NAME}/${customerId}/servicerequests`;
    const response = await ApiService.post(url, payload);
    return response;
}

async function getCustomerProfileCards(customerId, payload) {
    const url = `${SERVICE_CONFIG_URLS.CUSTOMERS.CONTROLLER_NAME}/${customerId}/servicecards`;
    const response = await ApiService.post(url, payload);
    return response;
}

async function verifyOtp(customerId, payload) {
    const url = `${SERVICE_CONFIG_URLS.CUSTOMERS.CONTROLLER_NAME}/${customerId}/consent`;
    const response = await ApiService.post(url, payload);
    return response;
}
async function verifyImportConsent(customerId, payload) {
    const url = `${SERVICE_CONFIG_URLS.CUSTOMERS.CONTROLLER_NAME}/import/${customerId}/consent`;
    const response = await ApiService.post(url, payload);
    return response;
}

async function resendOtp(customerId) {
    const url = `${SERVICE_CONFIG_URLS.CUSTOMERS.CONTROLLER_NAME}/${customerId}/consent`;
    const response = await ApiService.patch(url);
    return response;
}

async function getTicketDetails(ticketId) {
    const url = `${SERVICE_CONFIG_URLS.CUSTOMERS.CONTROLLER_NAME}/tickets/${ticketId}/detail`;
    const response = await ApiService.get(url);
    return response;
}

export default CustomerService;
