import React, { useEffect, useState } from "react";
import { Row, Col, Image, Spin } from "antd";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomerService from "../../services/customer.service";
import notificationService from "../../util/notification.service";

import "./index.less";
let Img = null;

function ConsentSuccess() {
    const { id, otp } = useParams();
    const { t } = useTranslation(["workshopOnboardingWizard"]);

    const [loading, setLoading] = useState(true);
    const [text, setText] = useState("workshopOnboardingWizard:consentVerification");

    useEffect(() => {
        async function load() {
            try {
                const { ok, response } = await CustomerService.verifyImportConsent(id, { otp });
                if (ok) {
                    Img = await require("../../assets/images/check.png");
                    setText("workshopOnboardingWizard:consentVerificationSuccess");
                    setLoading(false);
                    return notificationService.success(response.message);
                }
                throw response;
            } catch (response) {
                Img = await require("../../assets/images/cross.png");
                setText(response?.message || "workshopOnboardingWizard:consentVerificationFailed");
                setLoading(false);
            }
        }
        load();
    }, []);

    return (
        <>
            <div className="consent-success-container">
                <Row gutter={[25, 15]} className="margin-0" align="middle" justify="center">
                    {loading ? (
                        <Spin className="myLoader" indicator={true} size="large" />
                    ) : (
                        <Image preview={false} width={100} height={100} src={Img} />
                    )}
                </Row>

                <Row
                    gutter={[25, 15]}
                    type="flex"
                    justify="center"
                    align="center"
                    className="margin-0"
                >
                    <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className="wizard-heading contract-review-icon-center">
                            <h1>{t(text)}</h1>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ConsentSuccess;
