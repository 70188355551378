import React, { useState, useEffect } from "react";
import DocumentTitle from "react-document-title";
import { Button, Input, Form, Row, Col } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import notificationService from "../../util/notification.service";
import translationService from "../../util/translation.service";
import AuthService from "../../services/auth.service";
import { APP_NAME } from "../../util/constants/constant";
import "./reset-password.less";
import { Patterns } from "../../constants/ActionTypes";
import utilService from "../../util/utils.service";
import AppLocale from "../../languageProvider/index";
import { baseRoutes } from "../../routes/constant";

import LanguagePopover from "../../components/language";
import useDocumentTitle from "../../custom-hooks/useDocumentTitle";

const ResetPassword = (props) => {
    const location = useLocation();
    const isRemoveSuspension = location?.pathname.includes(baseRoutes.REMOVE_SUSPENSION);
    const { t } = useTranslation(["reset", "errors"]);
    const [form] = Form.useForm();
    const FormItem = Form.Item;
    const [isResetPasswordCallInProgress, setResetPasswordCallInProgress] = useState(false);
    const isLocaleOpen = localStorage.getItem("locale");
    let { culture, resetToken } = useParams();
    const history = useHistory();
    useDocumentTitle(t('resetPassPageTitle'));

    useEffect(() => {
        if (AppLocale.find((x) => x.id === culture)) {
            translationService.updateLocale(culture);
        }
    }, []);

    const ApiCall = (payload) => {
        if (isRemoveSuspension) return AuthService.removeSuspension(payload)
        else return AuthService.resetPassword(payload)
    }

    const handleSubmit = async (values) => {
        const resetPasswordPayload = {
            [isRemoveSuspension ? "password" : "updatedPassword"]: values.password,
            token: resetToken
        };

        setResetPasswordCallInProgress(true);

        const { ok, response } = await ApiCall(resetPasswordPayload);

        if (!ok) {
            notificationService.error(response.message);
        } else {
            notificationService.success(response.message);
            form.resetFields();
            utilService.redirectToLogin();
        }
        setResetPasswordCallInProgress(false);
    };

    return (
        <>
            <DocumentTitle title={`${APP_NAME} - ${props.title}`} />
            <Row justify="end" className="signup-gx-language">
                <LanguagePopover 
                    defaultVisible={isLocaleOpen == null ? true : false}
                    onLanguageSelect={(x) => (
                        history.replace(`/${x.id}/${isRemoveSuspension ? baseRoutes.REMOVE_SUSPENSION : baseRoutes.RESET_PASSWORD}/${resetToken}`)
                    )}
                />
            </Row>
            <Row align="top" justify="center" className="height-auto-min-100">
                <Col sm={24} xs={24} md={16} xl={16}>
                    <Form
                        form={form}
                        layout="vertical"
                        name="signup"
                        className="white-label-form"
                        onFinish={handleSubmit}
                    >
                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="margin-0"
                        >
                            <Col sm={24} xs={24} md={24} xl={24}>
                                <div className="signup_heading">
                                    <h1>{t(`reset:${isRemoveSuspension ? "removeSuspensionFormTitle" : "resetFormTitle"}`)}</h1>
                                </div>
                                <FormItem
                                    required={false}
                                    label={t("reset:newPasswordFormLabel")}
                                    name="password"
                                    validateTrigger="onBlur"
                                    rules={[
                                        { required: true, message: t("errors:newPasswordReq") },
                                        {
                                            pattern: Patterns.passwordPattern,
                                            message: (
                                                <pre className="passwordValidation">
                                                    {t("errors:passwordAtLeast8Char")}
                                                    <ul>
                                                        <li>{t("errors:oneUpperCase")} </li>
                                                        <li>{t("errors:oneNumber")}</li>
                                                        <li>{t("errors:oneSpecialCharacter")}</li>
                                                    </ul>
                                                </pre>
                                            )
                                        },
                                        {
                                            max: 100,
                                            message: t("errors:newPasswordMaxChar")
                                        }
                                    ]}
                                >
                                    <Input.Password
                                        size="large"
                                        type="password"
                                        placeholder="••••••••"
                                        iconRender={(visible) =>
                                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                        }
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="margin-0"
                        >
                            <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                <FormItem
                                    required={false}
                                    label={t("reset:confirmNewPasswordFormLabel")}
                                    name="confirmPassword"
                                    validateTrigger="onBlur"
                                    rules={[
                                        { required: true, message: t("errors:confirmPasswordReq") },
                                        {
                                            validator(_, value) {
                                                const password = form.getFieldValue("password");
                                                if (!value) {
                                                    return Promise.resolve();
                                                }
                                                if (password === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    t("errors:passwordNotMatched")
                                                );
                                            }
                                        }
                                    ]}
                                >
                                    <Input.Password
                                        size="large"
                                        type="password"
                                        placeholder="••••••••"
                                        iconRender={(visible) =>
                                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                        }
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="margin-0"
                        >
                            <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                <FormItem
                                    type="flex"
                                    justify="center"
                                    className="margin-0 submit-btn-center"
                                >
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        size="large"
                                        className="preserve-padding"
                                        loading={isResetPasswordCallInProgress}
                                        disabled={isResetPasswordCallInProgress}
                                    >
                                        {t(`reset:${isRemoveSuspension ? "removeSuspensionFormTitle" : "resetFormTitle"}`)}
                                    </Button>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default ResetPassword;
