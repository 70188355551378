import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Input, Button, Select } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import RegistrationService from "../../services/registration.service";
import { Patterns } from "../../constants/ActionTypes.js";
import utilService from "../../util/utils.service";
import notificationService from "../../util/notification.service";
import LocalStorageService from "../../util/local-storage.service";
import { STORAGE_CONST, APP_NAME } from "../../util/constants/constant";
import jwt_decode from "jwt-decode";
import "./sign-up.less";

import LanguagePopover from "../../components/language";
import useDocumentTitle from "../../custom-hooks/useDocumentTitle";

const SignUp = (props) => {
    const { t } = useTranslation(["signup", "errors"]);
    const { Option } = Select;
    const isLocaleOpen = localStorage.getItem("locale");

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const referralCode = queryParams.get("code") || "";

    const [form] = Form.useForm();
    const FormItem = Form.Item;
    const [isSignUpCallInProgress, setSignUpCallInProgress] = useState(false);
    const [cultureOptions, setCultureOptions] = useState([]);
    const [regionId, setRegionId] = useState(null);
    useDocumentTitle(t('signUpPageTitle'));

    const onFinish = async (payload) => {
        const updatedPayload = {
            ...payload,
            selectedCulture: "en-US"
        };
        setSignUpCallInProgress(true);
        const { ok, data, response } = await RegistrationService.registerWorkshop(updatedPayload);
        if (ok) {
            const decodedData = utilService.camelizeKeys(jwt_decode(data));
            decodedData.token = data;
            LocalStorageService.set(STORAGE_CONST.USER_INFO, JSON.stringify(decodedData));
            return utilService.redirectTo(`/${data.landingPage}`);
        } else {
            notificationService.error(response.message);
        }
        setSignUpCallInProgress(false);
    };

    const callback = (data) => {
        if (Object.keys(data).length) {
            setCultureOptions(data.cultures);
            setRegionId(data.key);
        } else {
            form.setFieldsValue({
                selectedCulture: null
            });
            setRegionId(null);
            setCultureOptions([]);
        }
    };

    return (
        <>
            <DocumentTitle title={`${APP_NAME} - ${props.title}`} />
            <Row justify="end" className="signup-gx-language">
                <LanguagePopover defaultVisible={isLocaleOpen == null ? true : false} />
            </Row>
            <Row align="top" justify="center" className="height-auto-min-100">
                <Col sm={24} xs={24} md={16} lg={16} xl={16} xxl={16}>
                    <Form
                        form={form}
                        layout="vertical"
                        name="registerWorkshop"
                        className="white-label-form"
                        onFinish={onFinish}
                    >
                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="margin-0"
                        >
                            <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                <div className="signup_heading">
                                    <h1>{t("signup:signUpFormTitle")}</h1>
                                </div>
                            </Col>
                        </Row>

                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="inputs-horizontal margin-0"
                        >
                            <Col sm={24} xs={24} md={18} lg={18} xl={18} xxl={18}>
                                <FormItem
                                    required={false}
                                    label={t("signup:workshopFormLabel")}
                                    className="mb-input"
                                    name="businessName"
                                    validateTrigger="onBlur"
                                    layout="vertical"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("errors:workshopNameReq")
                                        },
                                        {
                                            max: 50,
                                            message: t("errors:officialWorkshopNameMaxChar")
                                        },
                                        {
                                            min: 2,
                                            message: t("errors:workshopNameMinChar")
                                        },
                                        {
                                            pattern: Patterns.notNumberOnly,
                                            message: t("errors:workshopNameNumbersOnly")
                                        }
                                    ]}
                                >
                                    <Input
                                        size="large"
                                        placeholder={t("signup:workshopFormPlaceholder")}></Input>
                                </FormItem>
                            </Col>
                            <Col sm={24} xs={24} md={6} lg={6} xl={6} xxl={6}>
                                <FormItem
                                    required={false}
                                    label={t("signup:refcodeFormLabel")}
                                    className="mb-input"
                                    name="referralCode"
                                    validateTrigger="onBlur"
                                    layout="vertical"
                                    initialValue={referralCode}
                                >
                                    <Input
                                        size="large"
                                        maxLength={6}
                                        minLength={1}
                                        placeholder={t("signup:refcodeFormPlaceholder")}
                                        disabled={!!referralCode}
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>

                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="margin-0"
                        >
                            <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                <div className="manager_detail_heading">
                                    <h1>{t("signup:workshopManagerDetails")}</h1>
                                </div>
                            </Col>
                        </Row>

                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="inputs-horizontal margin-0"
                        >
                            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                                <FormItem
                                    required={false}
                                    label={t("signup:firstNameFormLabel")}
                                    name="ownerFirstName"
                                    validateTrigger="onBlur"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("errors:firstNameRequired")
                                        },
                                        {
                                            max: 26,
                                            message: t("errors:firstNameMaxChar(signIn)")
                                        },
                                        {
                                            min: 2,
                                            message: t("errors:firstNameMinChar")
                                        }
                                    ]}
                                    layout="vertical"
                                    className="mb-input"
                                >
                                    <Input
                                        size="large"
                                        placeholder={t("signup:firstNameFormPlaceholder")}
                                    ></Input>
                                </FormItem>
                            </Col>
                            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                                <FormItem
                                    required={false}
                                    label={t("signup:lastNameFormLabel")}
                                    name="ownerLastName"
                                    validateTrigger="onBlur"
                                    rules={[
                                        {
                                            max: 26,
                                            message: t("errors:lastNameMaxChar(signIn)")
                                        },
                                        {
                                            min: 2,
                                            message: t("errors:lastNameMinChar")
                                        }
                                    ]}
                                    layout="vertical"
                                    className="mb-input"
                                >
                                    <Input
                                        size="large"
                                        placeholder={t("signup:lastNameFormPlaceholder")}
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="inputs-horizontal margin-0"
                        >
                            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                                <FormItem
                                    required={false}
                                    label={t("signup:emailFormLabel")}
                                    name="email"
                                    validateTrigger="onBlur"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("errors:emailRequired")
                                        },
                                        {
                                            type: "email",
                                            message: t("errors:invalidEmail")
                                        }
                                    ]}
                                >
                                    <Input
                                        size="large"
                                        placeholder={t("signup:emailFormPlaceholder")}
                                    ></Input>
                                </FormItem>
                            </Col>
                            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                                <FormItem
                                    required={false}
                                    label={t("signup:phoneFormLabel")}
                                    name="phone"
                                    validateTrigger="onBlur"
                                    layout="vertical"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("errors:phoneReq")
                                        },
                                        {
                                            validator(_, value) {
                                                if (!value) {
                                                    return Promise.resolve();
                                                }
                                                if (
                                                    value &&
                                                    utilService.isValidPhoneNumber(value)
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    t("errors:phoneNumberNotValid")
                                                );
                                            }
                                        }
                                    ]}
                                    getValueFromEvent={(v, c, e, formattedValue) => formattedValue}
                                >
                                    <PhoneInput
                                        inputClass="ant-input width-100 signup-input-height"
                                        country={"ae"}
                                        placeholder={t("signup:phoneFormPlaceholder")}
                                        countryCodeEditable={true}
                                    />
                                </FormItem>
                            </Col>
                            {/* TODO REGION WORK AGAIN */}
                            {/* <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                                <FormItem
                                    required={false}
                                    label={t("signup:regionFormLabel")}
                                    name="regionId"
                                    rules={[{ required: true }]}
                                >
                                    <RegionLookup
                                        placeholder={t("signup:regionFormPlaceholder")}
                                        callback={callback}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                                <FormItem
                                    required={false}
                                    label={t("signup:cultureFormLabel")}
                                    name="selectedCulture"
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder={t("signup:cultureFormPlaceholder")}
                                        className="signup-input-height"
                                        size="large"
                                    >
                                        {cultureOptions.length > 0 &&
                                            cultureOptions?.map((x) => (
                                                <Option key={x} value={x}>
                                                    {x}
                                                </Option>
                                            ))}
                                    </Select>
                                </FormItem>
                            </Col> */}
                        </Row>
                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="inputs-horizontal margin-0"
                        >
                            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                                <FormItem
                                    required={false}
                                    label={t("signup:passwordFormLabel")}
                                    name="password"
                                    validateTrigger="onBlur"
                                    rules={[
                                        { required: true, message: t("errors:passwordReq") },
                                        {
                                            pattern: Patterns.passwordPattern,
                                            message: (
                                                <pre className="passwordValidation">
                                                    {t("errors:passwordAtLeast8Char")}
                                                    <ul>
                                                        <li>{t("errors:oneUpperCase")} </li>
                                                        <li>{t("errors:oneNumber")}</li>
                                                        <li>{t("errors:oneSpecialCharacter")}</li>
                                                    </ul>
                                                </pre>
                                            )
                                        },
                                        {
                                            max: 100,
                                            message: t("errors:passwordMaxChar")
                                        }
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="••••••••"
                                        size="large"
                                        iconRender={(visible) =>
                                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                        }
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                                <FormItem
                                    required={false}
                                    label={t("signup:confirmPasswordFormLabel")}
                                    name="confirm-password"
                                    validateTrigger="onBlur"
                                    rules={[
                                        { required: true, message: t("errors:confirmPasswordReq") },
                                        {
                                            validator(_, value) {
                                                const password = form.getFieldValue("password");
                                                if (!value) {
                                                    return Promise.resolve();
                                                }
                                                if (password === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    t("errors:passwordNotMatched")
                                                );
                                            }
                                        }
                                    ]}
                                >
                                    <Input.Password
                                        placeholder="••••••••"
                                        size="large"
                                        iconRender={(visible) =>
                                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                        }
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="margin-0"
                        >
                            <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                <FormItem className="margin-0 submit-btn-center">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        className="submit-button-mt"
                                        size="large"
                                        loading={isSignUpCallInProgress}
                                        disabled={isSignUpCallInProgress}
                                    >
                                        {t("signup:registerFormButtonName")}
                                    </Button>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default SignUp;
