import React from "react";
import { STORAGE_CONST } from "./constants/constant";
import AuthService from "./auth.service";
import { store } from "../next-app";
import i18n from "../i18n";

import { switchLanguage } from "../appRedux/slices/settings";

const translationService = {
    emailVerifySideContent,
    phoneVerifySideContent,
    getWOWSideContent,
    failedEmailVerifySideContent,
    loginSideContent,
    signupSideContent,
    forgetSideContent,
    resetPasswordSideContent,
    privacyPolicySideContent,
    wowRequestReceivedSideContent,
    updateLocale
};

function emailVerifySideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("verifyEmail:sideContentOne")}
            <br />
            <br />
            {t("verifyEmail:sideContentTwo")}
        </p>
    );
}

function phoneVerifySideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("pendingOtpVerification:sideContentOne")}
            <br />
            <br />
            {t("pendingOtpVerification:sideContentTwo")}
        </p>
    );
}

function failedEmailVerifySideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("pendingOtpVerification:failedEmailContentOne")}
            <br />
            <br />
            {t("pendingOtpVerification:failedEmailContentTwo")}
            <br />
            <br />
            {t("pendingOtpVerification:failedEmailContentThree")}
        </p>
    );
}

function loginSideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("login:sideContent")}
            <br />
            <br />
            <b className="or-text">{t("login:or")}</b>
        </p>
    );
}

function signupSideContent(t) {
    return <p className="auth-side-screen-description">{t("signup:sideContentOne")}</p>;
}

function forgetSideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("forgetPassword:sideContentOne")}
            <br />
            <br />
            {t("forgetPassword:sideContentTwo")}
        </p>
    );
}

function resetPasswordSideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {" "}
            {t("reset:resetSideContentOne")}
            <br />
            <br />
            {t("reset:resetSideContentTwo")}
            <br />
            <br />
            {t("reset:resetSideContentThree")}
        </p>
    );
}

function privacyPolicySideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("workshopOnboardingWizard:termsConditionContent")}
        </p>
    );
}

function getWOWSideContent(status, t) {
    switch (status) {
        case 0:
            return wowDetailsSideContent(t);
        case 1:
            return wowDocumentsSideContent(t);
        case 2:
            return wowQuestionnaireSideContent(t);
        case 3:
            return wowBrandsSideContent(t);
        case 4:
            return wowServicesSideContent(t);
        case 5:
            return wowEquipmentsSideContent(t);
        case 6:
            return wowAgreementSideContent(t);
        case 7:
            return wowSummarySideContent(t);
        case 8:
            return wowConfirmationSideContent(t);
        default:
            return wowDetailsSideContent(t);
    }
}

function wowDetailsSideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("detailsSideContent")}
            <br />
            <br />
            {t("detailsSideContentTwo")}
        </p>
    );
}

function wowDocumentsSideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {/* {t("documentsSideContentOne")}
            <br />
            <br /> */}
            {t("documentsSideContentTwo")}
            {/* <br />
            <br />
            {t("documentsSideContentThree")} */}
        </p>
    );
}

function wowQuestionnaireSideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("questionnaireSideContentOne")}
            <br />
            <br />
            {t("questionnaireSideContentTwo")}
        </p>
    );
}

function wowBrandsSideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("brandsSideContentOne")}
            <br />
            <br />
            {t("brandsSideContentTwo")}
        </p>
    );
}

function wowServicesSideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("servicesSideContentOne")}
            <br />
            <br />
            {t("servicesSideContentTwo")}
        </p>
    );
}

function wowEquipmentsSideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("equipmentSideContentOne")}
            <br />
            <br />
            {t("equipmentSideContentTwo")}
            <br />
            <br />
            {t("equipmentSideContentThree")}
        </p>
    );
}

function wowAgreementSideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("agreementSideContentOne")}
            <br />
            <br />
            {t("agreementSideContentTwo")}
        </p>
    );
}

function wowSummarySideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("summarySideContentOne")}
            <br />
            <br />
            {t("summarySideContentTwo")}
            <br />
            <br />
            {t("summarySideContentThree")}
        </p>
    );
}

function wowConfirmationSideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("confirmationSideContentOne")}
            <br />
            <br />
            {t("confirmationSideContentTwo")}
            <br />
            <br />
            {t("confirmationSideContentThree")}
        </p>
    );
}

function wowRequestReceivedSideContent(t) {
    return (
        <p className="auth-side-screen-description">
            {t("workshopOnboardingWizard:requestReceivedSideContent")}
        </p>
    );
}

function updateLocale(locale) {
    if (locale) {
        store.dispatch(switchLanguage(locale));
        i18n.changeLanguage(locale);
        AuthService.setPreferences(STORAGE_CONST.USER_LANGUAGE, locale);
        localStorage.setItem("locale", locale);
    }
}

export default translationService;
