import antdEs from "antd/lib/locale-provider/es_ES";

const ruRULang = {
    name: "Pусский", // Russian
    antdLocale: antdEs,
    i18nLocale: "ru-RU",
    momentLocale: "es",
    direction: "ltr",
    icon: "ru"
};
export default ruRULang;
