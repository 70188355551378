import antdZN from "antd/lib/locale-provider/zh_CN";

const zhCNLang = {
    name: "中国人", // Chinese
    antdLocale: antdZN,
    i18nLocale: "zh-CN",
    momentLocale: "es",
    direction: "ltr",
    icon: "cn"
};
export default zhCNLang;
