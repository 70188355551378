import antdNL from "antd/lib/locale-provider/nl_NL";

const nlNLLang = {
    name: "Dutch", // Dutch
    antdLocale: antdNL,
    i18nLocale: "nl-NL",
    momentLocale: "es",
    direction: "rtl",
    icon: "nl"
};
export default nlNLLang;
