import React, { useState } from "react";
import { Modal, Button, Col, Row } from "antd";
import "./styles.less";

export default (props) => {
    let titleArr = props?.title?.split(/[ ]+/);
    return (
        <>
            <Modal
                className="sos-custom-modal"
                visible={props.visible}
                closable={props.closable}
                onCancel={props.handleCancel}
                footer={null}
                width={props.modalWidth ?? 890}
                centered
            >
                <Row className="row-eq-height">
                    <Col className="left" xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                        <h3>
                            {titleArr?.map((item) => (
                                <span>{item}</span>
                            ))}
                        </h3>
                        {/* <div style={{ width: "100%" }}> */}
                        <img src={props.src} className="inner-image-modal" />
                        {/* </div> */}
                    </Col>
                    <Col
                        className={`${
                            props.noPadding ? "no-padding" : props.customClassForMainCol || "right"
                        } ${props?.fixedHeight && `fixed-height`} ${props.className}`}
                        xs={24}
                        sm={24}
                        md={18}
                        lg={18}
                        xl={18}
                        xxl={18}
                        justify="center"
                        align="middle"
                    >
                        {props.children}
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
