import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WrappedNormalResetPasswordForm from "../containers/reset-password/reset-password";
import SideBarWizardScreen from "../containers/side-bar-wizard-screen";
import { unAuthenticatedRoutes, commonRoutes } from "./constant";
import translationService from "../util/translation.service";

export default function UniversialAppRoutes() {
    const { t } = useTranslation([
        "login",
        "signup",
        "forgetPassword",
        "reset",
        "workshopOnboardingWizard"
    ]);
    return [
        <Route
            exact
            path={unAuthenticatedRoutes.RESET_PASSWORD}
            render={() => {
                return (
                    <SideBarWizardScreen
                        sideContent={translationService.resetPasswordSideContent(t)}
                    >
                        <WrappedNormalResetPasswordForm />
                    </SideBarWizardScreen>
                );
            }}
        />,
        <Route
            exact
            path={unAuthenticatedRoutes.REMOVE_SUSPENSION}
            render={() => {
                return (
                    <SideBarWizardScreen
                        sideContent={translationService.resetPasswordSideContent(t)}
                    >
                        <WrappedNormalResetPasswordForm />
                    </SideBarWizardScreen>
                );
            }}
        />
    ];
}
