const adminListReducer = (
  state = {
    pageNumber: 1,
    pageSize: 10,
    data: [],
    search: '',
    totalRecords: 0,
  },
  action,
) => {
  switch (action.type) {
    case 'UPDATE_ADMIN_LISTING':
      return action.payload;
      break;
    case 'UPDATE_ADMIN_LISTING_SEARCH':
      return {
        ...state,
        search: action.payload.search,
        pageNumber: action.payload.pageNumber,
      };
    default:
      return state;
  }
};
export default adminListReducer;
