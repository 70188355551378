import React from "react";

import { getTourContent } from "./contents";
import "./tour-content.less";

const Content = ({ title, content }) => {
    return (
        <div className="content-container">
            <h1>
                <b className="tour-title">{title}</b>
            </h1>
            <div style={{ padding: "20px 0px" }}>
                {content}
            </div>
        </div>
    );
};

export default Content;
