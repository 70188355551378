import antdEs from "antd/lib/locale-provider/es_ES";

const esUrLang = {
  name: 'Urdu',
  antdLocale: antdEs,
  i18nLocale: 'es-UR',
  momentLocale: 'es',
  direction: 'rtl',
  icon: 'pk'
};
export default esUrLang;
