import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WrappedNormalLoginForm from "../containers/sign-in";
import Signup from "../containers/sign-up";
import WrappedNormalForgetPasswordForm from "../containers/forget-password/forget-password";
import WrappedNormalResetPasswordForm from "../containers/reset-password/reset-password";
import EmailVerifiedScreen from "../containers/email-verified";
import SideBarWizardScreen from "../containers/side-bar-wizard-screen";
import PendingOtpVerification from "../containers/pending-otp-verification";
import ScanQR from "../containers/scan-qr-code";
import { WorkshopTermsConditions, UserTermsConditions } from "../containers/terms-conditions";
import { unAuthenticatedRoutes, commonRoutes } from "./constant";
import utilService from "../util/utils.service";
import translationService from "../util/translation.service";
import { ConsentSuccess } from "../containers/consent-success";
import UniversialAppRoutes from "./universal-app-routes";

export default function UnAuthenticatedApp() {
    const { t } = useTranslation([
        "login",
        "signup",
        "forgetPassword",
        "reset",
        "workshopOnboardingWizard"
    ]);
    return (
        <Switch>
            <Route
                exact
                path={unAuthenticatedRoutes.SIGN_UP}
                component={() => {
                    return (
                        <SideBarWizardScreen sideContent={translationService.signupSideContent(t)}>
                            <Signup />
                        </SideBarWizardScreen>
                    );
                }}
            />
            <Route
                exact
                path={unAuthenticatedRoutes.LOGIN}
                component={() => {
                    return (
                        <SideBarWizardScreen sideContent={translationService.loginSideContent(t)}>
                            <WrappedNormalLoginForm />
                        </SideBarWizardScreen>
                    );
                }}
            />
            <Route
                exact
                path={unAuthenticatedRoutes.FORGET_PASSWORD}
                component={() => {
                    return (
                        <SideBarWizardScreen sideContent={translationService.forgetSideContent(t)}>
                            <WrappedNormalForgetPasswordForm />
                        </SideBarWizardScreen>
                    );
                }}
            />
            {/* <Route
                exact
                path={unAuthenticatedRoutes.RESET_PASSWORD}
                render={() => {
                    return (
                        <SideBarWizardScreen
                            sideContent={translationService.resetPasswordSideContent(t)}
                        >
                            <WrappedNormalResetPasswordForm />
                        </SideBarWizardScreen>
                    );
                }}
            /> */}
            <Route
                exact
                path={commonRoutes.USER_TERMS_CONDITIONS}
                render={() => {
                    return (
                        <SideBarWizardScreen
                            sideContent={translationService.privacyPolicySideContent(t)}
                        >
                            <UserTermsConditions />
                        </SideBarWizardScreen>
                    );
                }}
            />
            <Route
                exact
                path={commonRoutes.CONSENT_SUCCESS}
                render={() => {
                    return (
                        <SideBarWizardScreen
                            sideContent={translationService.privacyPolicySideContent(t)}
                        >
                            <ConsentSuccess />
                        </SideBarWizardScreen>
                    );
                }}
            />
            <Route
                exact
                path={commonRoutes.WS_TERMS_CONDITIONS}
                render={() => {
                    return (
                        <SideBarWizardScreen
                            sideContent={translationService.privacyPolicySideContent(t)}
                        >
                            <WorkshopTermsConditions />
                        </SideBarWizardScreen>
                    );
                }}
            />
            <Route
                exact
                path={unAuthenticatedRoutes.SCAN_QR_CODE}
                component={() => {
                    return (
                        <SideBarWizardScreen>
                            <ScanQR />
                        </SideBarWizardScreen>
                    );
                }}
            />
            {/* Maybe this route is not used in unauthenticated routes */}
            {/* <Route
                path={unAuthenticatedRoutes.EMAIL_VERIFIED}
                exact
                component={() => {
                    return (
                        <SideBarWizardScreen>
                            <EmailVerifiedScreen />
                        </SideBarWizardScreen>
                    );
                }}
            /> */}

            <Route
                path={[
                    unAuthenticatedRoutes.PENDING_OTP_VERIFICATION,
                    unAuthenticatedRoutes.PENDING_OTP_VERIFICATION_CULTURE
                ]}
                exact
                render={(props) => {
                    return <PendingOtpVerification {...props} />;
                }}
            />
            {UniversialAppRoutes()}
            <Route exact path="*" component={WrappedNormalLoginForm}>
                <Redirect to={unAuthenticatedRoutes.LOGIN} />
            </Route>
        </Switch>
    );
}
