import antdDE from "antd/lib/locale-provider/de_DE";

const deDELang = {
    name: "Deutsche", // German
    antdLocale: antdDE,
    i18nLocale: "de-DE",
    momentLocale: "es",
    direction: "ltr",
    icon: "de"
};
export default deDELang;
