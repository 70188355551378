import antdAR from "antd/lib/locale-provider/ar_EG";

const arAELang = {
    name: "عربى", // Arabic
    antdLocale: antdAR,
    i18nLocale: "ar-AE",
    momentLocale: "es",
    direction: "rtl",
    icon: "ae"
};
export default arAELang;
