import antdKR from "antd/lib/locale-provider/ko_KR";

const koKRLang = {
    name: "한국어", // Korean
    antdLocale: antdKR,
    i18nLocale: "ko-KR",
    momentLocale: "es",
    direction: "rtl",
    icon: "kr"
};
export default koKRLang;
