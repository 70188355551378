import { createSlice, createAction } from "@reduxjs/toolkit";
import {
    LAYOUT_TYPE_FULL,
    NAV_STYLE_FIXED,
    THEME_COLOR_SELECTION_PRESET,
    THEME_TYPE_SEMI_DARK
} from "../../constants/ThemeSetting";
import AppLocale from "../../languageProvider/index";
import utilService from "../../util/utils.service";

const locationChange = createAction("@@router/LOCATION_CHANGE");

const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        history: [],
        token: "",
        navCollapsed: true,
        navStyle: NAV_STYLE_FIXED,
        layoutType: LAYOUT_TYPE_FULL,
        themeType: THEME_TYPE_SEMI_DARK,
        colorSelection: THEME_COLOR_SELECTION_PRESET,

        pathname: "",
        width: window.innerWidth,
        isDirectionRTL: false,
        locale: utilService.getLocale()
    },
    reducers: {
        toggleCollapsedSideNav: (state, action) => ({
            ...state,
            navCollapsed: action.payload
        }),
        updateWindowWidth: (state, action) => ({
            ...state,
            width: action.payload
        }),
        setThemeType: (state, action) => ({
            ...state,
            themeType: action.payload
        }),
        setThemeColor: (state, action) => ({
            ...state,
            colorSelection: action.colorSelection
        }),
        onNavStyleChange: (state, action) => ({
            ...state,
            navStyle: action.payload
        }),
        onLayoutTypeChange: (state, action) => ({
            ...state,
            layoutType: action.payload
        }),
        switchLanguage: (state, action) => ({
            ...state,
            locale: action.payload
        }),
        wsInventorySearch: (state, action) => ({
            ...state,
            search: action.payload
        }),
        spliceHistory: (state, action) => {
            const { payload: spliceParams = [] } = action;

            const newHistory = [...state.history];
            newHistory.splice(...spliceParams);

            return {
                ...state,
                history: newHistory
            };
        },
        setToken: (state, action) => ({
            ...state,
            token: action.payload
        })
    },
    extraReducers: (builder) => {
        builder
            .addCase(locationChange, (state, action) => {
                let newHistory = [...state.history];
                let { pathname, search } = action.payload.location;

                let staticPathname = utilService.createStaticPathname(pathname);

                let historyEntry = {
                    actualPathname: pathname + search,
                    staticPathname
                };

                // let { selectedCrumbIndex } = action.payload.location.state || {};

                // Slice history array till index of selected breadcrumb
                // if (!isNaN(selectedCrumbIndex)) newHistory.splice(selectedCrumbIndex);

                // Reset history if on base route or if first render
                // Could check base route like so, staticPathname.split("/").filter(Boolean).length === 1
                if (
                    action.payload.isFirstRendering ||
                    staticPathname.split("/").filter(Boolean).length === 1
                ) {
                    newHistory = [historyEntry];
                }
                // Add path to history if navigating forward
                else if (action.payload.action === "PUSH") {
                    newHistory.push(historyEntry);
                }
                // Remove path from history if navigating backward
                else if (action.payload.action === "POP") {
                    newHistory.pop();
                    // Add latest entry if list emptied
                    !newHistory.length && newHistory.push(historyEntry);
                }
                // Replace last entry if route is being replaced
                else if (action.payload.action === "REPLACE") {
                    // newHistory[newHistory.length - 1] = historyEntry;
                    newHistory.splice(-1, 1, historyEntry);
                }

                return {
                    ...state,
                    history: newHistory,
                    pathname: action.payload.location.pathname,
                    navCollapsed: false
                };
            })
            .addDefaultCase(() => {});
    }
});

export const {
    toggleCollapsedSideNav,
    updateWindowWidth,
    setThemeType,
    setThemeColor,
    onNavStyleChange,
    onLayoutTypeChange,
    switchLanguage,
    wsInventorySearch,
    spliceHistory,
    setToken
} = settingsSlice.actions;
export default settingsSlice.reducer;
