import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import settingsReducer from "../slices/settings";
import commonReducer from "../slices/common";
import adminDashboardReducer from "../../containers/admin-app/store/reducers";
import adminListingReducer from "../../containers/admin-list/store/reducers";
import tourReducer from "../slices/tour";
import chatReducer from "../slices/chat";
export default (history) =>
    combineReducers({
        adminDashboard: adminDashboardReducer,
        adminListing: adminListingReducer,
        router: connectRouter(history),
        settings: settingsReducer,
        commonData: commonReducer,
        tour: tourReducer,
        chat: chatReducer
    });
