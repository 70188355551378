import { QueryClient } from "react-query";

import NotificationService from "../util/notification.service";
import ApiService from "../util/api.service";

export const defaultQueryFn = async ({ queryKey: [link, method, body, config] }) => {
  const { ok, data, response, status } = await ApiService[method]?.(
    link,
    body,
    config,
  ) || {};

  if (ok) {
    //NotificationService.success(response?.message);
    return data;
  } else {
    status !== 403 && NotificationService.error(response?.message);
    throw new Error(response?.message);
  }
};

export default function configureReactQuery() {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
      }
    },
  })

  return client;
}
