import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import React from "react";
import ReactDOM from "react-dom";
import { AppContainer } from "react-hot-loader";
import MetaTags from "react-meta-tags";
import AppProvider from "./context";
import NextApp from "./next-app";
import registerServiceWorker from "./register-service-worker";
import "./styles/app.less";
import "./i18n";

const version = process.env.REACT_APP_VERSION;
const appInsightsKey = process.env.REACT_APP_APPINSIGHT_KEY;
const isEnableAppSight = process.env.REACT_APP_ISENABLE_APPINSIGHT;
const isEnableNewRelic = process.env.REACT_APP_ISENABLE_NEWRELIC;
var reactPlugin = new ReactPlugin();

if (JSON.parse(isEnableAppSight)) {
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: appInsightsKey,
            enableAutoRouteTracking: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            enableAjaxErrorStatusText: true,
            enableUnhandledPromiseRejectionTracking: true
        }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
}

if (JSON.parse(isEnableNewRelic)) {
    const script = document.createElement("script");
    script.src = "/js/new-relic.min.js";
    document.head.appendChild(script);
}

// Wrap the rendering in a function:
const render = (Component) => {
    ReactDOM.render(
        // Wrap App inside AppContainer
        <AppContainer>
            <AppProvider>
                <MetaTags>
                    <meta name="version" content={version} />
                </MetaTags>
                <Component />
            </AppProvider>
        </AppContainer>,
        document.getElementById("root")
    );
};

// Do this once
registerServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
    module.hot.accept("./next-app", () => {
        render(NextApp);
    });
}
