import antdJP from "antd/lib/locale-provider/ja_JP";

const jaJPLang = {
    name: "日本語", // Japanese
    antdLocale: antdJP,
    i18nLocale: "ja-JP",
    momentLocale: "es",
    direction: "rtl",
    icon: "jp"
};
export default jaJPLang;
