import React, { useState, useEffect } from "react";
import { Row, Col, Spin, Button, Form, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import OtpInput from "react-otp-input";
import DocumentTitle from "react-document-title";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, useLocation } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { APP_NAME, STORAGE_CONST } from "../../util/constants/constant";
import utilService from "../../util/utils.service";
import translationService from "../../util/translation.service";
import RegistrationService from "../../services/registration.service";
import { authenticatedRoutes } from "../../routes/constant";
import LocalStorageService from "../../util/local-storage.service";
import SideBarWizardScreen from "../side-bar-wizard-screen";
import authService from "../../util/auth.service";
import AppLocale from "../../languageProvider/index";
import "./index.less";
import notificationService from "../../util/notification.service";
import jwt_decode from "jwt-decode";

import LanguagePopover from "../../components/language";
import useDocumentTitle from "../../custom-hooks/useDocumentTitle";

const PendingOtpVerification = (props) => {
    const userInfo = LocalStorageService.getObject(STORAGE_CONST.USER_INFO);
    const { t } = useTranslation(["pendingOtpVerification", "errors"]);
    const location = useLocation();
    const history = useHistory();
    let { culture } = useParams();
    const useQuery = () => new URLSearchParams(location.search);
    const query = useQuery();
    const token = query.get("token");

    const [verificationCallInProgress, setVerificationCallInProgress] = useState(false);
    const [emailCallInProgress, setEmailCallInProgress] = useState(false);
    const [resendCallInProgress, setResendCallInProgress] = useState(false);
    const [isTokenNotExist, setIsTokenNotExist] = useState(false);
    const [isResendEmailCallInProgress, setIsResendEmailCallInProgress] = useState(false);
    const [otpValue, setOtpValue] = useState();
    const loadingIcon = <LoadingOutlined spin className="email-verified-loading-icon" />;
    const [form] = Form.useForm();
    const FormItem = Form.Item;
    useDocumentTitle(t('otpVerificationPageTitle'));

    useEffect(() => {
        const storageToken = authService.getToken();
        async function load() {
            setEmailCallInProgress(true);
            const { ok, data, response } = await AuthService.emailVerification({
                token: token ? utilService.getQueryParams(location.search) : storageToken
            });
            if (ok) {
                const decodedData = utilService.camelizeKeys(jwt_decode(data));
                decodedData.token = data;
                LocalStorageService.set(STORAGE_CONST.USER_INFO, JSON.stringify(decodedData));
                setIsTokenNotExist(false);
                setEmailCallInProgress(false);
            } else {
                setIsTokenNotExist(true);
                setEmailCallInProgress(false);
            }
        }
        if (
            userInfo.landingPage !==
                authenticatedRoutes.WORKSHOP_ADMIN.PENDING_OTP_VERIFICATION.replace("/", "") &&
            (token || storageToken)
        ) {
            setIsTokenNotExist(false);
            load();
        }
        if (AppLocale.find((x) => x.id === culture)) {
            translationService.updateLocale(culture);
        }
    }, []);

    const regenerateOtp = async () => {
        setResendCallInProgress(true);
        const { ok, data, response } = await RegistrationService.resendOtp();
        if (ok) {
            notificationService.success(response.message);
        } else {
            notificationService.error(response.message);
        }
        setResendCallInProgress(false);
    };

    const verifyOtp = async () => {
        setVerificationCallInProgress(true);
        const { ok, data, response } = await RegistrationService.verifyOtp({ otp: otpValue });
        if (ok) {
            userInfo.landingPage = data?.landingPage;
            LocalStorageService.set(STORAGE_CONST.USER_INFO, JSON.stringify(userInfo));
            notificationService.success(response.message);
            utilService.redirectTo(`/${data.landingPage}`);
        } else {
            notificationService.error(response.message);
        }
        setVerificationCallInProgress(false);
    };

    const handleSubmit = async (values) => {
        const payload = {
            email: values.email
        };

        setIsResendEmailCallInProgress(true);
        const { ok, data, response } = await AuthService.resendVerificationEmail(payload);
        if (ok) {
            notificationService.success(response.message);
            history.push(authenticatedRoutes.WORKSHOP_ADMIN.PENDING_EMAIL_VERIFICATION);
        } else {
            notificationService.error(response.message);
        }
        setIsResendEmailCallInProgress(false);
    };

    if (isTokenNotExist) {
        return (
            <>
                <SideBarWizardScreen
                    sideContent={translationService.failedEmailVerifySideContent(t)}
                >
                    <DocumentTitle title={`${APP_NAME} - ${props.title}`} />
                    <Row justify="end" className="signup-gx-language">
                        <LanguagePopover
                            onLanguageSelect={(x) => (
                                history.replace(`/${x.id}${authenticatedRoutes.WORKSHOP_ADMIN.PENDING_OTP_VERIFICATION}${location.search || ""}`)
                            )}
                        />
                    </Row>
                    <Row align="top" justify="center" className="height-auto-min-100">
                        <Col sm={24} xs={24} md={16} xl={16}>
                            <Form
                                form={form}
                                layout="vertical"
                                name="signup"
                                className="white-label-form"
                                onFinish={handleSubmit}
                            >
                                <Row
                                    gutter={[25, 15]}
                                    type="flex"
                                    justify="center"
                                    align="center"
                                    className="phone-verify"
                                >
                                    <Col sm={24} xs={24} md={24} xl={24}>
                                        <h3>{t(`emailVerifyFailedTitle`)}</h3>
                                    </Col>
                                    <Col sm={24} xs={24} md={24} xl={24} className="mb-40">
                                        <p>{t(`emailVerifyFailedText`)}</p>
                                    </Col>
                                    <Col sm={24} xs={24} md={24} xl={24} className="mb-40">
                                        <FormItem
                                            required={false}
                                            label={t(`emailAddressFormLabel`)}
                                            name="email"
                                            validateTrigger="onBlur"
                                            size="large"
                                            rules={[
                                                {
                                                    required: true
                                                },
                                                {
                                                    type: "email",
                                                    message: t("errors:invalidEmail")
                                                }
                                            ]}
                                        >
                                            <Input
                                                size="large"
                                                placeholder={t(`emailAddressFormPlaceholder`)}
                                            ></Input>
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row
                                    gutter={[25, 15]}
                                    type="flex"
                                    justify="center"
                                    align="center"
                                    className="inputs-horizontal"
                                ></Row>

                                <Row
                                    gutter={[25, 15]}
                                    type="flex"
                                    justify="center"
                                    // align="middle"
                                    className="margin-0"
                                >
                                    <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                        <FormItem className="margin-0">
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                                className="width-100"
                                                size="large"
                                                loading={isResendEmailCallInProgress}
                                                disabled={isResendEmailCallInProgress}
                                            >
                                                {t(`formButtonName`)}
                                            </Button>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </SideBarWizardScreen>
            </>
        );
    }

    return (
        <>
            <SideBarWizardScreen sideContent={translationService.phoneVerifySideContent(t)}>
                <DocumentTitle title={`${APP_NAME} - ${props.title}`} />
                <Row justify="end" className="signup-gx-language">
                    <LanguagePopover
                        onLanguageSelect={(x) => (
                            history.replace(`/${x.id}${authenticatedRoutes.WORKSHOP_ADMIN.PENDING_OTP_VERIFICATION}${location.search || ""}`)
                        )}
                    />
                </Row>
                {emailCallInProgress ? (
                    <Row align="middle" justify="center" className="height-auto-min-100 margin-0">
                        <Col>
                            <Spin indicator={loadingIcon} size="large"></Spin>
                        </Col>
                    </Row>
                ) : (
                    <Row justify="center" className="height-auto-min-100 ">
                        <Col sm={24} xs={24} md={16} lg={16} xl={16} xxl={16}>
                            <Row
                                type="flex"
                                justify="start"
                                className="inputs-horizontal phone-verify"
                            >
                                <Col
                                    sm={24}
                                    xs={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    className="mb-40"
                                >
                                    <h3>{t(`phoneVerifyTitle`)}</h3>
                                </Col>
                                <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                    <p>{t(`phoneVerifyText`)}</p>
                                </Col>
                                <Col
                                    className="otp-input-fields"
                                    sm={24}
                                    xs={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                >
                                    <OtpInput
                                        value={otpValue}
                                        onChange={(v) => setOtpValue(v)}
                                        className="otp-input"
                                        numInputs={4}
                                        placeholder="xxxx"
                                    />
                                </Col>
                            </Row>

                            <Row type="flex" justify="center" align="middle" className="margin-0">
                                <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                                    <Button
                                        type="primary"
                                        className="width-100"
                                        size="large"
                                        loading={resendCallInProgress}
                                        disabled={
                                            resendCallInProgress || verificationCallInProgress
                                        }
                                        onClick={regenerateOtp}
                                    >
                                        {t(`resendBtn`)}
                                    </Button>
                                </Col>
                                <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                                    <Button
                                        type="primary"
                                        className="width-100"
                                        size="large"
                                        disabled={
                                            verificationCallInProgress ||
                                            resendCallInProgress ||
                                            !otpValue ||
                                            otpValue?.length < 4
                                        }
                                        onClick={verifyOtp}
                                    >
                                        {t(`verifyBtn`)}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </SideBarWizardScreen>
        </>
    );
};

export default PendingOtpVerification;
