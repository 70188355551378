import React, { useState } from "react";
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { Button, Input, Form, Row, Col } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import AuthService from "../../services/auth.service";
import utilService from "../../util/utils.service";

import notificationService from "../../util/notification.service";
import LocalStorageService from "../../util/local-storage.service";
import { STORAGE_CONST, APP_NAME } from "../../util/constants/constant";
import { unAuthenticatedRoutes } from "../../routes/constant";
import jwt_decode from "jwt-decode";
import "./sign-in.less";

import LanguagePopover from "../../components/language";
import useDocumentTitle from "../../custom-hooks/useDocumentTitle";
import { useSelector } from "react-redux";

const SignIn = (props) => {
    const { token } = useSelector(({ settings }) => settings);
    const isLocaleOpen = localStorage.getItem("locale");
    const { t } = useTranslation(["login", "errors"]);
    const FormItem = Form.Item;
    const [form] = Form.useForm();
    useDocumentTitle(t("loginPageTitle"));

    const [isSignInCallInProgress, setSignInCallInProgress] = useState(false);

    const onFinish = async (values) => {
        const payload = {
            email: values.email,
            password: values.password,
            firebaseToken: token
        };
        setSignInCallInProgress(true);
        const { ok, data, response } = await AuthService.login(payload);
        if (ok) {
            const { token } = data;
            const decodedData = utilService.camelizeKeys(jwt_decode(token));
            decodedData.token = token;
            LocalStorageService.set(STORAGE_CONST.USER_INFO, JSON.stringify(decodedData));
            return utilService.redirectTo(`/${decodedData.landingPage}`);
        } else {
            notificationService.error(response?.message);
        }
        setSignInCallInProgress(false);
    };

    return (
        <>
            <DocumentTitle title={`${APP_NAME} - ${props.title}`} />
            <Row justify="end" className="signup-gx-language">
                <LanguagePopover defaultVisible={isLocaleOpen == null ? true : false} />
            </Row>
            <Row align="top" justify="center" className="height-auto-min-100">
                <Col sm={24} xs={24} md={16} xl={16}>
                    <Form
                        form={form}
                        layout="vertical"
                        name="signup"
                        className="white-label-form"
                        onFinish={onFinish}
                    >
                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="margin-0"
                        >
                            <Col sm={24} xs={24} md={24} xl={24}>
                                <div className="signup_heading">
                                    <h1>{t("login:loginFormTitle")}</h1>
                                </div>
                                <FormItem
                                    required={false}
                                    label={t("login:emailAddressFormLabel")}
                                    name="email"
                                    validateTrigger="onBlur"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("errors:emailAddressRequired")
                                        },
                                        {
                                            type: "email",
                                            message: t("errors:invalidEmail")
                                        },
                                        {
                                            max: 320,
                                            message: t("errors:emailAddressMaxChar")
                                        },
                                        {
                                            min: 3,
                                            message: t("errors:emailAddressMinChar")
                                        }
                                    ]}
                                >
                                    <Input
                                        size="large"
                                        placeholder={t("login:emailAddressFormPlaceholder")}
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="margin-0"
                        >
                            <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                <FormItem
                                    required={false}
                                    label={t("login:passwordFormLabel")}
                                    name="password"
                                    validateTrigger="onBlur"
                                    rules={[
                                        { required: true, message: t("errors:passwordReq") },
                                        {
                                            max: 100,
                                            message: t("errors:passwordMaxChar")
                                        },
                                        {
                                            min: 8,
                                            message: t("errors:passwordMinChar")
                                        }
                                    ]}
                                >
                                    <Input.Password
                                        type="password"
                                        size="large"
                                        placeholder="••••••••"
                                        iconRender={(visible) =>
                                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                        }
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={[25, 15]} type="flex" justify="center" className="margin-0">
                            <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                <FormItem className="margin-0 submit-btn-center">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        className="submit-button-mt"
                                        size="large"
                                        loading={isSignInCallInProgress}
                                        disabled={isSignInCallInProgress}
                                    >
                                        {t("login:loginFormButtonName")}
                                    </Button>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="middle"
                            className="margin-0"
                        >
                            <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                <Link
                                    to={unAuthenticatedRoutes.FORGET_PASSWORD}
                                    className="text-color-white"
                                >
                                    {t("login:forgotPasswordLinkName")}
                                </Link>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default SignIn;
