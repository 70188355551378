import React from 'react'
import utilService from '../util/utils.service'

export {AuthProvider, useAuth}

const AuthContext = React.createContext()

function AuthProvider(props) {
  return (
    <AuthContext.Provider
      value={{
        login: utilService.noop,
      }}
      {...props}
    ></AuthContext.Provider>
  )
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context;
}
