import React from "react";
import get from "lodash.get";
import pickby from "lodash.pickby";
import * as unionby from "lodash.unionby";
import * as lodashIsEmpty from "lodash.isempty";
import { BasicScript } from "script-loading-toolkit";
import * as FileSaver from "file-saver";
import parsePhoneNumber from "libphonenumber-js";
import { unAuthenticatedRoutes, authenticatedRoutes } from "../routes/constant";
import { roles } from "../constants/constant";
import { DEFAULT_LANGUAGE } from "./constants/constant";
import AuthService from "./auth.service";
import AppLocale from "../languageProvider/index";
import { camelCase } from "lodash";
import dateTimeService from "./date-time.service";
import { Patterns } from "../constants/ActionTypes";

const utilService = {
    loginUrl: unAuthenticatedRoutes.LOGIN,
    defaultUrl: authenticatedRoutes.WORKSHOP_ADMIN.DASHBOARD,
    baseUrl: process.env.REACT_APP_BASE_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    chatBaseUrl: `https://${process.env.REACT_APP_CHAT_BASE_URL}`,
    chatUrl: `wss://${process.env.REACT_APP_CHAT_BASE_URL}/websocket`,
    toUsPhoneNumber,
    removePhonMasking,
    getValue,
    pickWhen,
    unionBy,
    toDollar,
    toNumber,
    formatAddress,
    getSelectPickerValue,
    redirectToLogin,
    redirectTo,
    redirectToReturnUrl,
    getUrlParameterByName,
    isEmpty,
    convertPropertyNames,
    titleCase,
    noop,
    posToNeg,
    googleAddressParser,
    isObject,
    format,
    loadDynamicScript,
    getGooglePlaceScript,
    getSocketSingalR,
    getClosest,
    getQueryParams,
    getNumericValue,
    forObject,
    filteredArr,
    getYears,
    exportToFile,
    getUrlParam,
    antdFormValidationMessages,
    downloadPDFData,
    arrayBufferToBase64,
    isValidPhoneNumber,
    replaceNullWithPlaceholder,
    reduceValuesToString,
    textOverflow,
    roundOff,
    toFloatFromCurrency,
    defaultRedirection,
    roleBasedRedirection,
    formateCurrencyToNumber,
    groupsObjectsAlphabetically,
    withValueLimit,
    createIdStringFromObject,
    getLocale,
    getLocaleData,
    getLocaleApi,
    camelizeKeys,
    splitCamelPascalCase,
    alphabeticallySort,
    getPartsTotals,
    getLaborTotals,
    createStaticPathname,
    createDynamicUrl,
    thousandSeparator
};

const regionsHash = {
    street_line: "short_name",
    street_number: "short_name",
    street_address: "short_name",
    route: "short_name",
    sublocality_level_1: "short_name",
    sublocality_level_2: "short_name",
    sublocality_level_3: "short_name",
    locality: "short_name",
    administrative_area_level_1: "short_name",
    country: "short_name",
    postal_code: "short_name"
};

const customHash = {
    street_line: "street_line",
    street_number: "street_number",
    street_address: "street",
    route: "route",
    sublocality_level_1: "street",
    sublocality_level_2: "street",
    sublocality_level_3: "street",
    locality: "city",
    state: "state",
    administrative_area_level_1: "state",
    country: "country",
    postal_code: "zip"
};

const fileExtensions = {
    excel: {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        extension: ".xlsx"
    },
    csv: {
        type: "text/csv",
        extension: ".csv"
    },
    pdf: {
        type: "application/pdf",
        extension: ".pdf"
    }
};

function replaceNullWithPlaceholder(value, placeHolder = "--") {
    if (!value) {
        return placeHolder;
    }
    return value;
}

function reduceValuesToString(...values) {
    return values.filter((value) => value || value === false || value === 0).join(" ");
}

function getClosest(ele, selector) {
    return ele.closest(selector);
}

function isObject(value) {
    return value && typeof value === "object" && value.constructor === Object;
}

function noop() {}

function filteredArr(arr) {
    return arr.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
}

function getValue(...param) {
    return get(...param);
}

function pickWhen(...param) {
    return pickby(...param);
}

function unionBy(...param) {
    return unionby(...param);
}

function removePhonMasking(number) {
    if (!number) return number;
    return ("" + number).replace(/\D/g, "");
}

function toUsPhoneNumber(number, extension = "") {
    let num = ("" + number).replace(/\D/g, "");
    let output;
    if (num.length > 10) {
        output = number;
    } else {
        let m = num.match(/^(\d{3})(\d{3})(\d{1,4})$/);
        output = !m ? num : "(" + m[1] + ") " + m[2] + "-" + m[3];
    }
    return extension ? output + ", Ext. " + ("" + extension).replace(/\D/g, "") : output;
}

function format(number, n, x, s, c) {
    const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? "\\D" : "$"})`;
    const num = number.toFixed(Math.max(0, ~~n));

    return (c ? num.replace(".", c) : num).replace(new RegExp(re, "g"), `$&${s || ","}`);
}

function toDollar(number) {
    let formattedValue;
    var negativeSignFlag = false;
    if (!number) return "$0.00";
    if (number < 0) {
        negativeSignFlag = true;
    }

    formattedValue = format(Math.abs(number), 2, 3, ",", ".");
    if (negativeSignFlag) return "-" + formattedValue;
    else return "$" + formattedValue;
}

function convertPropertyNames(obj, converterFn) {
    let r,
        value,
        t = Object.prototype.toString.apply(obj);
    if (t === "[object Object]") {
        r = {};
        for (let propname in obj) {
            value = obj[propname];
            r[converterFn(propname)] = convertPropertyNames(value, converterFn);
        }
        return r;
    } else if (t === "[object Array]") {
        r = [];
        for (let i = 0, L = obj.length; i < L; ++i) {
            value = obj[i];
            r[i] = convertPropertyNames(value, converterFn);
        }
        return r;
    }
    return obj;
}

function redirectToLogin(loginUrl = utilService.loginUrl) {
    let returnUrl = encodeURIComponent(
        window.location.pathname.replace(/[//]+/, "") + window.location.search
    );
    utilService.redirectTo(loginUrl + "?returnUrl=" + returnUrl);
}

function redirectTo(url) {
    window.location.href = utilService.baseUrl + url;
}

function redirectToReturnUrl() {
    utilService.redirectTo(
        utilService.getUrlParameterByName("returnUrl")
            ? "/" + utilService.getUrlParameterByName("returnUrl")
            : utilService.defaultUrl
    );
}

function getUrlParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regexS = `[\\?&]${name}=([^&#]*)`;
    const regex = new RegExp(regexS);
    const results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function isEmpty(value) {
    return lodashIsEmpty(value);
}

function titleCase(str = "") {
    if (!str) return str;
    return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
            return word.replace(word[0], word[0].toUpperCase());
        })
        .join(" ");
}

function formatAddress(address = {}, emptyText = "") {
    let addrs = "";

    if (!isEmpty(address)) {
        addrs += address.streetAddress ? address.streetAddress.trim() + ". " + "\n" : "";
        address.city = address.city ? address.city.toLowerCase() : address.city;
        address.city = address.city
            ? address.city.replace(/(?:^|\s)\w/g, function (match) {
                  return match.toUpperCase();
              })
            : address.city;
        addrs += address.city ? address.city : "";
        addrs +=
            address.state && addrs.length
                ? ", " + address.state
                : address.state
                ? address.state
                : "";
        addrs +=
            (address.zipCode || address.zip) && address.state
                ? " " + (address.zipCode || address.zip)
                : (address.zipCode || address.zip) && address.city && !address.state
                ? ", " + (address.zipCode || address.zip)
                : "";
        addrs += address.country ? " " + address.country : "";
    } else {
        addrs = emptyText;
    }
    return addrs;
}

function numberFormat(number, n, x, s, c) {
    const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? "\\D" : "$"})`;
    const num = number.toFixed(Math.max(0, ~~n));

    return (c ? num.replace(".", c) : num).replace(new RegExp(re, "g"), `$&${s || ","}`);
}

function toNumber(input, decimal = 2) {
    if (input && !isNaN(input)) {
        return numberFormat(input, decimal, 3);
    }
    return input;
}

function getSelectPickerValue(arr, val) {
    return arr.find((arr) => arr.value === val);
}

function posToNeg(num) {
    return -Math.abs(num);
}

function googleAddressParser(addressObj) {
    if (!addressObj) {
        return addressObj;
    }
    const locationObj = {};
    const address_components = addressObj.address_components;
    const place_id = addressObj.place_id;
    if (!address_components) {
        return locationObj;
    }
    locationObj["place_id"] = place_id;
    for (var i = 0; i < address_components.length; i++) {
        const addressType = address_components[i].types[0];
        if (regionsHash[addressType]) {
            var key = customHash[addressType];
            locationObj[key] = address_components[i][regionsHash[addressType]];
        }
    }
    var street_address = "";
    street_address += locationObj.street_number ? locationObj.street_number : "";
    street_address +=
        locationObj.street_line && street_address.length
            ? " " + locationObj.street_line
            : locationObj.street_line
            ? locationObj.street_line
            : "";
    street_address +=
        locationObj.route && street_address.length
            ? " " + locationObj.route
            : locationObj.route
            ? locationObj.route
            : "";
    locationObj.street_address = street_address;
    return locationObj;
}

function loadDynamicScript(libraryName, url, callback) {
    if (!loadDynamicScript.isScriptLoaded) {
        const script = document.createElement("script");
        script.src = url; // URL for the third-party library being loaded.
        script.id = libraryName; // e.g., googleMaps or stripe
        document.body.appendChild(script);

        script.onload = () => {
            if (callback) callback();
            loadDynamicScript.isScriptLoaded = true;
        };
    }

    if (callback && loadDynamicScript.isScriptLoaded) callback();
}

function getGooglePlaceScript() {
    if (getGooglePlaceScript.googleMap) {
        return getGooglePlaceScript.googleMap;
    }
    getGooglePlaceScript.googleMap = new BasicScript();
    getGooglePlaceScript.googleMap.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyDCxv5qVGEtuM7lFjBKyzZ2KLcBATQc8as&libraries=places";
    return getGooglePlaceScript.googleMap;
}

function getSocketSingalR() {
    if (getSocketSingalR.socketSignalR) {
        return getSocketSingalR.socketSignalR;
    }
    getSocketSingalR.socketSignalR = new BasicScript();
    getSocketSingalR.socketSignalR.src =
        utilService.baseUrl + "/ext/vendors/socket/jquery.signalR.min.js";
    return getSocketSingalR.socketSignalR;
}

function getNumericValue(value) {
    return value.replace(/\D/g, "");
}

function forObject(obj, target) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            target(key, obj[key], obj);
        }
    }
}

//function to get years till current years
function getYears(fromYear = 1900) {
    let years = [];

    for (let year = fromYear; year <= new Date().getFullYear(); year++) {
        years.push(year);
    }

    return years;
}

function exportToFile(data, fileName, type) {
    var fileMeta = fileExtensions[type];
    const response = new Blob([data], { type: fileMeta.type });
    FileSaver.saveAs(response, fileName + fileMeta.extension);
}

function arrayBufferToBase64(arraybuffer) {
    const base64String = btoa(String.fromCharCode(...new Uint8Array(arraybuffer)));
    return base64String;
}

function downloadPDFData(url) {
    let hiddenElement = document.createElement("a");
    hiddenElement.href = url;
    hiddenElement.click();
}

function getUrlParam(urlParam, search) {
    return new URLSearchParams(urlParam).get(search);
}

function antdFormValidationMessages(t) {
    const validateMessages = {
        required: t("formValidationMessages:emailRequired", { label: "${label}" }),
        types: {
            email: t("formValidationMessages:emailNotValid"),
            number: t("formValidationMessages:numberNotValid")
        },
        number: {
            range: t("formValidationMessages:rangeInBetween", {
                label: "${label}",
                min: "${min}",
                max: "${max}"
            })
        }
    };
    return validateMessages;
}

function isValidPhoneNumber(phoneNumber) {
    const parsedPhone = parsePhoneNumber(`${phoneNumber.startsWith("+") ? "" : "+"}${phoneNumber}`);
    if (parsedPhone) {
        return parsedPhone.isValid();
    }
    return false;
}

function textOverflow(string, stringLenth = 26) {
    if (string && string.length > stringLenth) {
        return string.substring(0, stringLenth).trim() + "...";
    }
    return string;
}

function roundOff(number, to = 2) {
    if (!number) {
        return number;
    }
    const roundNumber = parseFloat(number).toFixed(to);
    return roundNumber;
}
function toFloatFromCurrency(currencyValue) {
    return currencyValue
        ? parseFloat(currencyValue.toString().replace(/[^\d.]/g, ""))
        : currencyValue;
}

function defaultRedirection() {
    const role = AuthService.getRole();
    if (role === roles.workshopAdmin) {
        return authenticatedRoutes.WORKSHOP_ADMIN.DASHBOARD;
    } else {
        return authenticatedRoutes.SYSTEM_ADMIN.WORKSHOP_APPROVAL;
    }
}

function roleBasedRedirection() {
    const role = AuthService.getRole();
    if (role === roles.workshopAdmin) {
        return redirectTo(authenticatedRoutes.WORKSHOP_ADMIN.WORKSHOP_ONBOARD_WIZARD);
    } else {
        return redirectTo(authenticatedRoutes.SYSTEM_ADMIN.WORKSHOP_APPROVAL);
    }
}

function getQueryParams(url = "") {
    let search = url.split("?")?.[1];
    if (!search) {
        return {};
    }

    let queryParams = search.split("&");
    var chunks = queryParams[0].split("token=");
    var value = chunks[1];
    return value;
}

function groupsObjectsAlphabetically(data) {
    let groupsDataAlphabetically = data.reduce((r, e) => {
        // get first letter of name of current element
        let alphabet = e.name[0];

        // if there is no property in accumulator with this letter create it
        if (!r[alphabet]) r[alphabet] = { alphabet, record: [e] };
        // if there is push current element to children array for that letter
        else r[alphabet].record.push(e);

        // return accumulator
        return r;
    }, {});

    return Object.values(groupsDataAlphabetically);
}

function withValueLimit(inputObj, MAX_VAL = 9999999999) {
    const { value } = inputObj;
    if (value <= MAX_VAL) return inputObj;
}

function createIdStringFromObject(object, seperator = "-", ...keys) {
    if (typeof object === "string") {
        try {
            object = JSON.parse(object);
        } catch (e) {
            return;
        }
    }

    if (typeof object === "object" && object != null && keys.length) {
        let id = "";

        keys.forEach((key, index) => (id += (index ? seperator : "") + object[key]));

        return id;
    }
}

function getLocale() {
    return localStorage.getItem("locale") || DEFAULT_LANGUAGE;
}

function getLocaleData(locale) {
    return (
        AppLocale.find((x) => x.id === locale) || AppLocale.find((x) => x.id === DEFAULT_LANGUAGE)
    );
}

function getLocaleApi(store) {
    const {
        settings: { locale }
    } = store.getState();

    return process.env.REACT_APP_CULTURE_API === "true"
        ? `/${locale}${process.env.REACT_APP_API_VERSION}`
        : process.env.REACT_APP_API_VERSION;
}

function camelizeKeys(obj) {
    try {
        if (JSON.parse(obj) instanceof Object) obj = JSON.parse(obj);
    } catch {}
    if (Array.isArray(obj)) {
        return obj.map((v) => camelizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelCase(key)]: camelizeKeys(obj[key])
            }),
            {}
        );
    }
    return obj;
}

function splitCamelPascalCase(string) {
    const splitString = string
        // insert a space before all caps except first if no space present
        .replace(/\B([A-Z])/g, " $1");

    return splitString[0].toUpperCase() + splitString.slice(1);
}

function alphabeticallySort(a, b, sortBy) {
    // Use toUpperCase() to ignore character casing
    const bandA = a[sortBy].toUpperCase();
    const bandB = b[sortBy].toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
        comparison = 1;
    } else if (bandA < bandB) {
        comparison = -1;
    }
    return comparison;
}

// App-specific - SOS
function getPartsTotals(parts, discount = 0) {
    discount = 1 - discount / 100;
    let totalPrice = 0;
    let totalUnitPrice = 0;
    parts &&
        parts.forEach((el) => {
            let totalQuantity = +el.quantity;
            let batchQuantity = el?.partDetail?.reduce(
                (total, part) => total + +(part.selectedQuantity || part.quantity),
                0
            );
            if (el.retailPrice !== 0) {
                totalPrice +=
                    (el.retailPrice && typeof el.retailPrice !== "string"
                        ? el.retailPrice * +el.quantity || 0
                        : el?.partDetail
                        ? el.partDetail.reduce((acc, next) => {
                              return (acc +=
                                  next.retailPrice * (next.selectedQuantity || next.quantity));
                          }, 0)
                        : el?.manufacturerId?.retailPrice * el.quantity || 0) * discount;
                // totalPrice *= discount;
            }
            console.log({ totalPrice });
            totalUnitPrice += el?.partDetail
                ? el.partDetail.reduce((acc, next) => {
                      return (acc +=
                          (next.unitCost || next.unitPrice) *
                          (next.selectedQuantity || next.quantity));
                  }, 0)
                : el?.manufacturerId?.unitCost * el.quantity || 0;
            // if (el.partDetail) totalPrice += Math.max(0, totalQuantity - batchQuantity) * el.partDetail?.[0]?.retailPrice;
        });
    const totalProfit = totalPrice - totalUnitPrice;
    // const totalPercProfit = totalPrice
    //     ? Math.abs(100 - (totalUnitPrice * 100) / totalPrice)
    //     : 0;
    const totalPercProfit = totalPrice
        ? ((totalPrice - totalUnitPrice) / (totalUnitPrice || 1)) * 100
        : 0;
    return [totalPrice, totalUnitPrice, totalProfit, totalPercProfit];
}

function formateCurrencyToNumber(value) {
    switch (typeof value) {
        case "number":
            return value;
        case "string":
            const splitValue = value.split("");
            const formatedNumber = +splitValue
                .reduce((acc, next, index) => {
                    if (next === "." && index !== 0) {
                        if (
                            !Number.isInteger(+splitValue[index - 1]) ||
                            !Number.isInteger(+splitValue[index + 1])
                        ) {
                            return acc;
                        }
                    }
                    return `${acc}${next}`;
                }, "")
                .replace(/[^\.\d]/g, "");
            return formatedNumber || 0;
        default:
            return 0;
    }
}

function getLaborTotals(labor, discount = 0) {
    discount = 1 - discount / 100;
    let totalSaleValue = 0;
    let totalCost = 0;
    let profit = 0;
    let percProfit = 0;
    labor.map((el) => {
        // Total sale value without job time
        // totalSaleValue += el.saleValue || 0;
        // test case for sameer.
        // console.log('result', formateCurrencyToNumber('9.5b.'));
        // console.log('result', formateCurrencyToNumber('$.9.5b'));
        // console.log('result', formateCurrencyToNumber('$.9.5b'));
        // console.log('result', formateCurrencyToNumber('$.9.5؋'));
        // console.log('result', formateCurrencyToNumber('$.9.5₼'));
        // console.log('result', formateCurrencyToNumber('$9.5S/.'));
        // console.log('result', formateCurrencyToNumber('.9S/.'));
        // console.log('result', formateCurrencyToNumber('د.إ5.50'));
        // console.log('result', formateCurrencyToNumber('B/ 100.16'));
        // console.log('result', formateCurrencyToNumber('B/.100.16'));
        const estHours = dateTimeService.convertTimeIntoMinute(el.estimatedHours) / 60;
        if (!estHours) return;
        // Total sale value with job time
        totalSaleValue += (formateCurrencyToNumber(el.saleValue) * estHours || 0) * discount;

        totalCost += Number(el.costPerHour) || 0;

        // Formula without job time
        // profit += el.costPerHour ? +(el.saleValue || 0) - +(el.costPerHour || 0) : 0;

        // Formula with job time
        profit +=
            (el.costPerHour
                ? ((formateCurrencyToNumber(el.saleValue) || 0) - +(el.costPerHour || 0)) * estHours
                : 0) * discount;

        percProfit +=
            ((formateCurrencyToNumber(el.saleValue) - +el.costPerHour) / +el.costPerHour) *
            100 *
            discount;
    });
    return [totalSaleValue, totalCost, profit, percProfit];
}

function createStaticPathname(pathname = "") {
    return pathname
        .split("/")
        .map((path) =>
            (path && !isNaN(path)) || new RegExp(Patterns.uuidIdentifier).test(path) ? ":id" : path
        )
        .join("/");
}

function thousandSeparator(number) {
    if (typeof number === "number")
        return number.toString().replace(Patterns.thousandSeparator, ",");
    return "need number";
}

function createDynamicUrl(dynamicUrl, object) {
    for (const key in object) {
        dynamicUrl = dynamicUrl.replace(`{${key}}`, object[key]);
    }
    return dynamicUrl;
}

export default utilService;
