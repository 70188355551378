import React from "react";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import utilService from "../util/utils.service";
import AppLocale from "../languageProvider";
import { stripePromise } from "../next-app";
import { Elements } from "@stripe/react-stripe-js";

const AuthenticatedAppRoutes = React.lazy(() => import("../routes/authenticated-app-routes"));

const Routes = (props) => {
    const { t, i18n } = useTranslation(["formValidationMessages"]);
    const locale = useSelector(({ settings }) => settings.locale);
    const currentAppLocale = utilService.getLocaleData(locale);
    const validateMessages = utilService.antdFormValidationMessages(t);

    console.log("translation files", { currentAppLocale }, locale);

    console.log({ i18n });

    const currentLanguage = i18n.language === "ca-ES" ? "es-ES" : i18n.language;

    return (
        <Elements options={{ locale: currentLanguage }} stripe={stripePromise}>
            <ConfigProvider locale={currentAppLocale.lang.antdLocale} form={{ validateMessages }}>
                <AuthenticatedAppRoutes />
            </ConfigProvider>
        </Elements>
    );
};

export default Routes;
