import ApiService from "../util/api.service";
import { SERVICE_CONFIG_URLS } from "./config";
import AuthService from "../util/auth.service";
import { STORAGE_CONST } from "../util/constants/constant";

const RegionService = {
    getCompliances,
    getBrands,
    getRegions,
    getRegionsList,
    getContractInfo,
    getTruckDriverCompliances
};

const userInfo = AuthService.getUserInfo();
const regionId = userInfo?.regionId;
async function getCompliances(region) {
    const url = `${SERVICE_CONFIG_URLS.REGION.CONTROLLER_NAME}/${
        region || JSON.parse(localStorage[STORAGE_CONST.USER_INFO]).regionId
    }/compliances`;
    const response = await ApiService.post(url, {
        offset: 0,
        count: 0
    });
    return response;
}

async function getBrands(payload) {
    const url = `${SERVICE_CONFIG_URLS.REGION.CONTROLLER_NAME}/${
        regionId || JSON.parse(localStorage[STORAGE_CONST.USER_INFO]).regionId
    }/brands`;
    const response = await ApiService.post(url, payload);
    return response;
}

async function getRegions(payload) {
    const url = `${SERVICE_CONFIG_URLS.REGION.CONTROLLER_NAME}`;
    const response = await ApiService.post(url, payload);
    return response;
}

async function getRegionsList(payload) {
    const url = `${SERVICE_CONFIG_URLS.REGION.CONTROLLER_NAME}/list`;
    const response = await ApiService.post(url, payload);
    return response;
}

async function getContractInfo(contractType, config, id) {
    const url = `/regions/${
        id || regionId || JSON.parse(localStorage[STORAGE_CONST.USER_INFO]).regionId
    }/contract/${contractType}/download`;
    const response = await ApiService.get(url, null, config);
    return response;
}

async function getTruckDriverCompliances() {
    const url = `${SERVICE_CONFIG_URLS.REGION.CONTROLLER_NAME}/truckdrivercompliances`;
    const response = await ApiService.post(url);
    return response;
}

export default RegionService;
