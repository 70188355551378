import React from "react";
import CacheControl from "./CacheControl";
import { Provider } from "react-redux";
import { QueryClientProvider } from "react-query";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./appRedux/store";
import { setToken } from "./appRedux/slices/settings";
import configureReactQuery from "./react-query-config";
import FullPageLoader from "./components/full-page-loader";
import "./assets/vendors/style";
import "./styles/wieldy.less";
import AuthService from "./util/auth.service";
import UnAuthenticatedAppRoutes from "./routes/unauthenticated-app-routes";
import MainApp from "./containers/app/main-app";
import { requestFirebaseNotificationPermission, messaging } from "./util/firebase.service";
import { loadStripe } from "@stripe/stripe-js";

const STRIP_PUBLISH_KEY =
    "pk_live_51KCKjwEERpbJMj6NayOT6rtRdm4C0VIQ9mGRSLbOk1k579sj1Evxf9osvrJKBdr2XW8JYil7wTtujG3Fycspu4RB00khpp4tCt";
export const store = configureStore();
export const queryClient = configureReactQuery();
export const stripePromise = loadStripe(STRIP_PUBLISH_KEY);

requestFirebaseNotificationPermission()
    .then((firebaseToken) => {
        // eslint-disable-next-line no-console
        listenForegroundNotifications();
        console.log(firebaseToken);
        store.dispatch(setToken(firebaseToken));
    })
    .catch((err) => {
        console.log("Notification Permission not allowed ", err.message);
    });

function listenForegroundNotifications() {
    messaging.onMessage(async (payload) => {
        console.log("Received foreground message ", payload);
        const {
            data: {
                title,
                body,
                actionType,
                entityId,
                serviceRequestId: primaryId,
                fromUserName: username,
                OutputFile: fileUrl
            } = {}
        } = payload;

        const notification = new Notification(title, {
            body,
            data: { actionType, entityId, primaryId, username }
        });

        if (actionType == window.pushNotificationsModule.types.BulkImportDownload) {
            notification.onclick = () => window.open(fileUrl, "_blank");
            return;
        }

        const redirectURL = window.pushNotificationsModule.generateRedirectURL(
            actionType,
            username || primaryId || entityId
        ); // eslint-disable-line

        if (redirectURL) notification.onclick = () => history.replace(redirectURL);
    });
}

const NextApp = () => {
    const isTokenExist = AuthService.isTokenExist();

    return (
        <CacheControl>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }

                return (
                    <React.Suspense fallback={<FullPageLoader />}>
                        <QueryClientProvider client={queryClient}>
                            <Provider store={store}>
                                <ConnectedRouter history={history}>
                                    {isTokenExist ? <MainApp /> : <UnAuthenticatedAppRoutes />}
                                </ConnectedRouter>
                            </Provider>
                        </QueryClientProvider>
                    </React.Suspense>
                );
            }}
        </CacheControl>
    );
};

export default NextApp;
