export const tourSteps = {
    step1: "step-1",
    step2: "step-2",
    step3: "step-3",
    step4: "step-4",
    step5: "step-5",
    step6: "step-6",
    step7: "step-7"
};

export const tourTargetElements = {
    SIDEBAR_CUSTOMERS: "sidebar-customers",
    SIDEBAR_VEHICLES: "sidebar-vehicles",
    SIDEBAR_SERVICE_REQUESTS: "sidebar-service-requests",
    SIDEBAR_SERVICES: "sidebar-services",
    SIDEBAR_WORKSHOP_INVENTORY: "sidebar-workshop-inventory",
    SIDEBAR_QUICK_JOB_CARD: "sidebar-quick-job-card",
    SIDEBAR_EMPLOYEES: "sidebar-employees",
    SIDEBAR_COUNTER_SALES: "sidebar-counter-sales",
    SIDEBAR_INSURANCE: "sidebar-insurance",
    SIDEBAR_PARTS_PURCHASE: "sidebar-parts-purchase",
    SIDEBAR_RECOVERY_TRUCK: "sidebar-recovery-truck",

    TOPBAR_NOTIFICATION: "topbar-notification",
    TOPBAR_WALLET: "topbar-wallet",
    TOPBAR_CHAT: "topbar-chat",

    WORKSHOP_PROFILE: "#workshop-profile-sidebar-icon",
    WORKSHOP_INVENTORY: "#workshop-inventory-sidebar-icon",
    DASHBOARD_VEHICLE_ID: "#dashboard-vehicle-id-lookup",
    DASHBOARD_ADD_CUSTOMER: "#dashboard-add-customer-button",
    DASHBOARD_ADD_VEHICLE: "#dashboard-add-vehicle-button",
    DASHBOARD_VEHICLE_ACTIONS: ".dashboard-vehicle-action-column",

    JOB_CARD_SERVICE_LOOKUP: "#job-card-service-lookup",
    JOB_CARD_ADD_PART: "#job-card-add-part-button",
    JOB_CARD_PART_LOOKUP: "#job-card-part-lookup-0",
    JOB_CARD_EMPLOYEE_LOOKUP: "#job-card-employee-lookup-0",

    WORKSHOP_PROFILE_EMPLOYEES_TAB:
        "#workshop-profile-tabs .ant-tabs-nav-list .ant-tabs-tab:nth-child(3)",

    WORKSHOP_INVENTORY_INVOICE_TAB:
        "#workshop-inventory-tabs .ant-tabs-nav-list .ant-tabs-tab:nth-child(2)",

    ADD_INVOICE_IMPORT_BUTTON: "#add-invoice-import-button"
};

export const TOUR_STEP = "TOUR_STEP";
export const TOUR = "TOUR";
