import antdFR from "antd/lib/locale-provider/fr_FR";

const esFrLang = {
    name: "Français", // French
    antdLocale: antdFR,
    i18nLocale: "fr-FR",
    momentLocale: "en-us",
    direction: "ltr",
    icon: "fr"
};
export default esFrLang;
