import React, { useState } from "react";
import DocumentTitle from "react-document-title";
import { Button, Input, Form, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import AuthService from "../../services/auth.service";
import notificationService from "../../util/notification.service";
import { APP_NAME } from "../../util/constants/constant";
import "./forget-password.less";

import LanguagePopover from "../../components/language";
import useDocumentTitle from "../../custom-hooks/useDocumentTitle";

const ForgetPassword = (props) => {
    const { t } = useTranslation(["forgetPassword", "errors"]);
    const [form] = Form.useForm();
    const FormItem = Form.Item;
    const isLocaleOpen = localStorage.getItem("locale");
    const [isForgotPasswordCallInProgress, setForgotPasswordCallInProgress] = useState(false);
    useDocumentTitle(t('forgetPasswordPageTitle'));

    const handleSubmit = async (values) => {
        const payload = {
            email: values.email
        };

        setForgotPasswordCallInProgress(true);

        const { ok, data, response } = await AuthService.forgetPassword(payload);
        setForgotPasswordCallInProgress(false);
        if (!ok) {
            notificationService.error(response.message);
        } else if (!data.isValid) {
            notificationService.error(response.message);
        } else {
            notificationService.success(response.message);
            form.resetFields();
        }
    };

    return (
        <>
            <DocumentTitle title={`${APP_NAME} - ${props.title}`} />
            <Row justify="end" className="signup-gx-language">
                <LanguagePopover defaultVisible={isLocaleOpen == null ? true : false} />
            </Row>
            <Row align="top" justify="center" className="height-auto-min-100">
                <Col sm={24} xs={24} md={16} xl={16}>
                    <Form
                        form={form}
                        layout="vertical"
                        name="signup"
                        className="white-label-form"
                        onFinish={handleSubmit}
                    >
                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="margin-0"
                        >
                            <Col sm={24} xs={24} md={24} xl={24}>
                                <div className="signup_heading">
                                    <h1>{t("forgetPassword:forgetPasswordFormTitle")}</h1>
                                </div>
                                <FormItem
                                    required={false}
                                    label={t("forgetPassword:emailAddressFormLabel")}
                                    name="email"
                                    validateTrigger="onBlur"
                                    size="large"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("errors:emailAddressRequired")
                                        },
                                        {
                                            type: "email",
                                            message: t("errors:invalidEmail")
                                        }
                                    ]}
                                >
                                    <Input
                                        size="large"
                                        placeholder={t("forgetPassword:emailAddressFormLabel")}
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="inputs-horizontal margin-0"
                        ></Row>

                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="center"
                            className="margin-0"
                        >
                            <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                <FormItem
                                    type="flex"
                                    justify="center"
                                    className="margin-0 forget-password-button"
                                >
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        className="submit-button-mt"
                                        size="large"
                                        loading={isForgotPasswordCallInProgress}
                                        disabled={isForgotPasswordCallInProgress}
                                    >
                                        {t("forgetPassword:forgetPasswordFormButtonName")}
                                    </Button>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row
                            gutter={[25, 15]}
                            type="flex"
                            justify="center"
                            align="middle"
                            className="margin-0"
                        ></Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};


const WrappedNormalForgetPasswordForm = ForgetPassword;

export default WrappedNormalForgetPasswordForm;
