import antdPT from "antd/lib/locale-provider/pt_PT";

const ptPTLang = {
    name: "Português", // Portuguese
    antdLocale: antdPT,
    i18nLocale: "pt-PT",
    momentLocale: "es",
    direction: "ltr",
    icon: "pt"
};
export default ptPTLang;
