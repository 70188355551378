import antdEs from "antd/lib/locale-provider/es_ES";

const esEsLang = {
    name: "Español", // Spanish
    antdLocale: antdEs,
    i18nLocale: "es-ES",
    momentLocale: "es",
    direction: "ltr",
    icon: "es"
};
export default esEsLang;
