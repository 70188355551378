const paginationConstant = {
    PAGE_SIZE: 10
};

const vehiclePaginationConstant = {
    PAGE_SIZE: 5
};

const workshopCustomerPagination = {
    PAGE_SIZE: 3
};

const pageProperties = {
    WIDTH: 1024,
    OVERFLOW_Y: 250
};

const stringLengths = {
    twentyFive: 25,
    thirty: 30
};

const serviceStatusFilterOptions = {
    onGoing: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 1000
    },
    serviceRequest: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 3010
    },
    serviceCards: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 3050
    },
    quotedRequest: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 3020
    },
    completed: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 3030
    },
    pendingApproval: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 3040
    }
};

const serviceCardStatusFilterOptions = {
    onGoing: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 3030
    },
    drafts: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 3000
    },
    completed: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 700
    },
    pendingApproval: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 3010
    },
    approved: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 550
    }
};

const serviceRequestStatusFilterOptions = {
    requests: {
        Type: "ValueFilter",
        Name: "statusCode",
        ValueType: "int",
        Comparator: "",
        Value: 1
    },
    quotations: {
        Type: "ValueFilter",
        Name: "statusCode",
        ValueType: "int",
        Comparator: "",
        Value: 3
    }
};

const recoveryTrucksFilterOptions = {
    pendingApproval: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 1000
    },
    approved: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 1100
    },
    rejected: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: 1200
    },
    all: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "string",
        Comparator: "",
        Value: "all"
    }
};

const workShopStatusCode = {
    INVALID: 0,
    REQUEST_CREATED: 100,
    DETAILS_FILLED: 200,
    DOCUMENTS_UPLOADED: 300,
    QUESTIONNAIRE_ANSWERED: 400,
    BRANDS_SELECTED: 500,
    SERVICES_SELECTED: 600,
    EQUIPMENT_SELECTED: 700,
    TERMS_AGREEMENT: 800,
    CONTRACT_UPLOADED: 900,
    PENDING_ADMIN_APPROVAL: 1000,
    CONTRACT_SENT: 1010,
    CONTRACT_SIGNED: 1020,
    APPROVED: 1100,
    REJECTED: 1200,
    IN_REVIEW: 1300,
    INACTIVE: 1400
};

const workShopUpdateStatusCode = {
    APPROVE: 1,
    REJECT: 2,
    REVIEW: 3,
    ACTIVATE: 5,
    DEACTIVATE: 4
};

const workshopStatusFilterOptions = {
    [workShopStatusCode.PENDING_ADMIN_APPROVAL]: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: workShopStatusCode.PENDING_ADMIN_APPROVAL
    },
    [workShopStatusCode.APPROVED]: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: workShopStatusCode.APPROVED
    },
    [workShopStatusCode.REJECTED]: {
        Type: "ValueFilter",
        Name: "status",
        ValueType: "int",
        Comparator: "",
        Value: workShopStatusCode.REJECTED
    }
};

const workshopStatus = {
    [workShopStatusCode.INVALID]: {
        Code: [workShopStatusCode.INVALID],
        Color: "black",
        Name: "Invalid"
    },
    [workShopStatusCode.REQUEST_CREATED]: {
        Code: [workShopStatusCode.REQUEST_CREATED],
        Color: "black",
        Name: "Request Created"
    },
    [workShopStatusCode.DETAILS_FILLED]: {
        Code: [workShopStatusCode.DETAILS_FILLED],
        Color: "black",
        Name: "Details Filled"
    },
    [workShopStatusCode.DOCUMENTS_UPLOADED]: {
        Code: [workShopStatusCode.DOCUMENTS_UPLOADED],
        Color: "black",
        Name: "Documents Uploaded"
    },
    [workShopStatusCode.QUESTIONNAIRE_ANSWERED]: {
        Code: [workShopStatusCode.QUESTIONNAIRE_ANSWERED],
        Color: "black",
        Name: "Questionnaire Answered"
    },
    [workShopStatusCode.BRANDS_SELECTED]: {
        Code: [workShopStatusCode.BRANDS_SELECTED],
        Color: "black",
        Name: "Brands Selected"
    },
    [workShopStatusCode.SERVICES_SELECTED]: {
        Code: [workShopStatusCode.SERVICES_SELECTED],
        Color: "black",
        Name: "Services Selected"
    },
    [workShopStatusCode.EQUIPMENT_SELECTED]: {
        Code: [workShopStatusCode.EQUIPMENT_SELECTED],
        Color: "black",
        Name: "Equipment Selected"
    },
    [workShopStatusCode.TERMS_AGREEMENT]: {
        Code: [workShopStatusCode.TERMS_AGREEMENT],
        Color: "black",
        Name: "Terms Agreements"
    },
    [workShopStatusCode.CONTRACT_UPLOADED]: {
        Code: [workShopStatusCode.CONTRACT_UPLOADED],
        Color: "black",
        Name: "Contract Uploaded"
    },
    [workShopStatusCode.PENDING_ADMIN_APPROVAL]: {
        Code: [workShopStatusCode.PENDING_ADMIN_APPROVAL],
        Color: "orange",
        Name: "Pending Admin Approval"
    },
    [workShopStatusCode.APPROVED]: {
        Code: [workShopStatusCode.APPROVED],
        Color: "green",
        Name: "Approved"
    },
    [workShopStatusCode.REJECTED]: {
        Code: [workShopStatusCode.REJECTED],
        Color: "red",
        Name: "Rejected"
    },
    [workShopStatusCode.IN_REVIEW]: {
        Code: [workShopStatusCode.APPROVED],
        Color: "green",
        Name: "In-Review"
    }
};

const accountStatusCode = {
    Invalid: 0,
    Active: 400,
    Invited: 300,
    Disabled: 100,
    Inactive: 200,
    UnderRegistration: 260,
    PendingOTPVerification: 250,
    CustomerConsentVerification: 255
};

const accountStatus = {
    [accountStatusCode.Invalid]: {
        Code: [accountStatusCode.Invalid],
        Color: "black",
        Name: "Invalid"
    },
    [accountStatusCode.Disabled]: {
        Code: [accountStatusCode.Disabled],
        Color: "black",
        Name: "Disabled"
    },
    [accountStatusCode.Inactive]: {
        Code: [accountStatusCode.Inactive],
        Color: "red",
        Name: "Inactive"
    },
    [accountStatusCode.PendingOTPVerification]: {
        Code: [accountStatusCode.PendingOTPVerification],
        Color: "orange",
        Name: "Pending OTP Verification"
    },
    [accountStatusCode.UnderRegistration]: {
        Code: [accountStatusCode.UnderRegistration],
        Color: "orange",
        Name: "Under Registration"
    },
    [accountStatusCode.Invited]: {
        Code: [accountStatusCode.Invited],
        Color: "green",
        Name: "Invited"
    },
    [accountStatusCode.Active]: {
        Code: [accountStatusCode.Active],
        Color: "green",
        Name: "Active"
    }
};

const serviceRequestStatusCode = {
    Created: 100,
    VehicleSelected: 200,
    ServicesSelected: 300,
    WorkshopSelected: 400,
    PendingWorkshopReview: 500,
    PendingCustomerApproval: 600,
    Approved: 650,
    WorkStarted: 700,
    WorkCompleted: 800,
    Disputed: 900,
    DisputeResolved: 1000,
    Rejected: 1100,
    Finished: 1200
};

const serviceRequestStatus = {
    [serviceRequestStatusCode.Created]: {
        Code: [serviceRequestStatusCode.Created],
        Color: "#629e79",
        Name: "Created",
        class: "success"
    },
    [serviceRequestStatusCode.VehicleSelected]: {
        Code: [serviceRequestStatusCode.VehicleSelected],
        Color: "#629e79",
        Name: "Vehicle Selected",
        class: "success"
    },
    [serviceRequestStatusCode.ServicesSelected]: {
        Code: [serviceRequestStatusCode.ServicesSelected],
        Color: "#629e79",
        Name: "Services Selected",
        class: "success"
    },
    [serviceRequestStatusCode.WorkshopSelected]: {
        Code: [serviceRequestStatusCode.WorkshopSelected],
        Color: "#629e79",
        Name: "Workshop Selected",
        class: "success"
    },
    [serviceRequestStatusCode.PendingWorkshopReview]: {
        Code: [serviceRequestStatusCode.PendingWorkshopReview],
        Color: "#ff8500",
        Name: "Pending Workshop Review",
        class: "confirm"
    },
    [serviceRequestStatusCode.PendingCustomerApproval]: {
        Code: [serviceRequestStatusCode.PendingCustomerApproval],
        Color: "#ff8500",
        Name: "Pending Customer Approval",
        class: "confirm"
    },
    [serviceRequestStatusCode.Approved]: {
        Code: [serviceRequestStatusCode.Approved],
        Color: "#f68e56",
        Name: "Approved",
        class: "success"
    },
    [serviceRequestStatusCode.WorkStarted]: {
        Code: [serviceRequestStatusCode.WorkStarted],
        Color: "#f68e56",
        Name: "Work Started",
        class: "success"
    },
    [serviceRequestStatusCode.WorkCompleted]: {
        Code: [serviceRequestStatusCode.WorkCompleted],
        Color: "#8dc63f",
        Name: "Work Completed",
        class: "success"
    },
    [serviceRequestStatusCode.Disputed]: {
        Code: [serviceRequestStatusCode.Disputed],
        Color: "#f5222d",
        Name: "Disputed",
        class: "danger"
    },
    [serviceRequestStatusCode.DisputeResolved]: {
        Code: [serviceRequestStatusCode.DisputeResolved],
        Color: "#629e79",
        Name: "Dispute Resolved",
        class: "success"
    },
    [serviceRequestStatusCode.Rejected]: {
        Code: [serviceRequestStatusCode.Rejected],
        Color: "#f5222d",
        Name: "Rejected",
        class: "danger"
    },
    [serviceRequestStatusCode.Finished]: {
        Code: [serviceRequestStatusCode.Finished],
        Color: "#629e79",
        Name: "Finished",
        class: "success"
    }
};

const serviceCardStatusCode = {
    Invalid: 0,
    Created: 100,
    PendingCustomerApproval: 200,
    AwaitingPayment: 300,
    PaymentReceived: 400,
    PaymentDeclined: 500,
    Approved: 550,
    WorkStarted: 600,
    WorkCompleted: 700,
    Disputed: 800,
    DisputeResolved: 900,
    Rejected: 1000,
    Finished: 1100
};

const serviceCardStatus = {
    [serviceCardStatusCode.Invalid]: {
        Code: [serviceCardStatusCode.Invalid],
        Color: "black",
        Name: "Invalid"
    },
    [serviceCardStatusCode.Created]: {
        Code: [serviceCardStatusCode.Created],
        Color: "black",
        Name: "Created"
    },
    [serviceCardStatusCode.PendingCustomerApproval]: {
        Code: [serviceCardStatusCode.PendingCustomerApproval],
        Color: "black",
        Name: "Pending Approval"
    },
    [serviceCardStatusCode.AwaitingPayment]: {
        Code: [serviceCardStatusCode.AwaitingPayment],
        Color: "black",
        Name: "Awaiting Payment"
    },
    [serviceCardStatusCode.PaymentReceived]: {
        Code: [serviceCardStatusCode.PaymentReceived],
        Color: "black",
        Name: "Payment Received"
    },
    [serviceCardStatusCode.PaymentDeclined]: {
        Code: [serviceCardStatusCode.PaymentDeclined],
        Color: "black",
        Name: "Payment Declined"
    },
    [serviceCardStatusCode.Approved]: {
        Code: [serviceCardStatusCode.Approved],
        Color: "#8dc63f",
        Name: "Approved"
    },
    [serviceCardStatusCode.WorkStarted]: {
        Code: [serviceCardStatusCode.WorkStarted],
        Color: "#8dc63f",
        Name: "Work Started"
    },
    [serviceCardStatusCode.WorkCompleted]: {
        Code: [serviceCardStatusCode.WorkCompleted],
        Color: "#8dc63f",
        Name: "Work Completed"
    },
    [serviceCardStatusCode.Disputed]: {
        Code: [serviceCardStatusCode.Disputed],
        Color: "black",
        Name: "Disputed"
    },
    [serviceCardStatusCode.DisputeResolved]: {
        Code: [serviceCardStatusCode.DisputeResolved],
        Color: "black",
        Name: "Dispute Resolved"
    },
    [serviceCardStatusCode.Rejected]: {
        Code: [serviceCardStatusCode.Rejected],
        Color: "black",
        Name: "Rejected"
    },
    [serviceCardStatusCode.Finished]: {
        Code: [serviceCardStatusCode.Finished],
        Color: "black",
        Name: "Finished"
    }
};

const serviceOptionStatusCode = {
    Invalid: 0,
    Created: 100,
    Approved: 200,
    Rejected: 300
};

const serviceOptionStatus = {
    [serviceOptionStatusCode.Invalid]: {
        Code: [serviceOptionStatusCode.Invalid],
        Color: "black",
        Name: "Invalid"
    },
    [serviceOptionStatusCode.Created]: {
        Code: [serviceOptionStatusCode.Created],
        Color: "black",
        Name: "Created"
    },
    [serviceOptionStatusCode.Approved]: {
        Code: [serviceOptionStatusCode.Approved],
        Color: "black",
        Name: "Approved"
    },
    [serviceOptionStatusCode.Rejected]: {
        Code: [serviceOptionStatusCode.Rejected],
        Color: "black",
        Name: "Rejected"
    }
};

const listingTypesMap = {
    serviceRequests: [
        { value: "onGoing", label: "On-going" },
        { value: "completed", label: "Completed" }
    ],
    serviceCards: [
        { value: "onGoing", label: "On-going" },
        { value: "drafts", label: "Drafts" },
        { value: "pendingApproval", label: "Pending Approval" },
        { value: "approved", label: "Approved" },
        { value: "completed", label: "Completed" }
    ] // serviceCards order is used in service cards action.
};

const backendJobCodes = {
    PENDINGAPPROVAL: [130, 120, 300, 400, 500]
};

const vehicleStatusCode = {
    Invalid: 0,
    Created: 100,
    PendingOTPVerification: 200,
    Active: 300
};
const vehicleStatus = {
    [vehicleStatusCode.Invalid]: {
        Code: [vehicleStatusCode.Invalid],
        Key: "Invalid",
        Color: "black",
        Name: "OTP Verification"
    },
    [vehicleStatusCode.Created]: {
        Code: [vehicleStatusCode.Created],
        Key: "Created",
        Color: "black",
        Name: "Created"
    },
    [vehicleStatusCode.PendingOTPVerification]: {
        Code: [vehicleStatusCode.PendingOTPVerification],
        Key: "PendingOTPVerification",
        Color: "red",
        Name: "OTP Verification"
    },
    [vehicleStatusCode.Active]: {
        Code: [vehicleStatusCode.Active],
        Key: "Active",
        Color: "green",
        Name: "Generate QR"
    }
};

const journeyStatusCodes = {
    Requested: 100,
    LookingForDriver: 200,
    DriverAssigned: 300,
    DriverAccepted: 400,
    PickupLeg: 600,
    ArrivedAtPickup: 700,
    DropoffLeg: 900,
    Completed: 1200,
    WorkshopCancelled: 1500
};

const ServiceRequestFilteringStatus = {
    InProgress: {
        Code: 3010
    },
    Quoted: {
        Code: 3020
    },
    Completed: {
        Code: 3030
    }
};

const InventoryStatusCode = {
    Invalid: 0,
    OutOfStock: 1,
    Available: 2
};

const InventoryStatus = {
    [InventoryStatusCode.Invalid]: {
        Code: [InventoryStatusCode.Invalid],
        Color: "black",
        Name: "Invalid"
    },
    [InventoryStatusCode.OutOfStock]: {
        Code: [InventoryStatusCode.OutOfStock],
        Color: "red",
        Name: "Inactive"
    },
    [InventoryStatusCode.Available]: {
        Code: [InventoryStatusCode.Available],
        Color: "green",
        Name: "Disabled"
    }
};

const workshopUserActionsStatus = {
    INVALID: 0,
    ACTIVATE: 1,
    INACTIVATE: 2,
    DELETE: 3
};

const workshopUserDesignationOption = [
    {
        value: "seniorTech"
    },
    {
        value: "masterTech"
    },
    {
        value: "technician"
    },
    {
        value: "bodyshopTech"
    },
    {
        value: "sparePartManager"
    },
    {
        value: "helper"
    },
    {
        value: "electrician"
    },
    {
        value: "painter"
    },
    {
        value: "panelBeater"
    },
    {
        value: "fitter"
    },
    {
        value: "cleaner"
    },
    {
        value: "cfo"
    },
    {
        value: "ceo"
    },
    {
        value: "accountManager"
    }
];

const workshopUserDesignationTranslation = {
    seniorTech: "Senior Tech",
    masterTech: "Master Tech",
    technician: "Technician",
    bodyshopTech: "Bodyshop Technician",
    sparePartManager: "Spare Parts Manager",
    helper: "Helper / Apprentice",
    electrician: "Electrician",
    painter: "Painter",
    panelBeater: "Panel Beater",
    fitter: "Fitter",
    cleaner: "Cleaner / Detailer",
    ceo: "CEO/MD",
    cfo: "CFO",
    accountManager: "Accounts Manager"
};

const workshopUserRoles = [
    {
        label: "Super Admin",
        value: "WorkshopSuperAdmin",
        desc: "superAdminDesc"
    },
    {
        label: "Workshop Manager",
        value: "WorkshopAdmin",
        desc: "workshopManagerDesc"
    },
    {
        label: "Service Advisor",
        value: "WorkshopUser",
        desc: "serviceAdvisorDesc"
    },
    {
        label: "Team Member",
        value: "TeamMember",
        desc: "teamMemberDesc"
    },
    {
        label: "Inventory Manager",
        value: "InventoryManager",
        desc: "inventoryManagerDesc"
    }
];

const bankAccountTypes = [
    {
        value: "Saving Account"
    },
    {
        value: "Current Account"
    },
    {
        value: "Fixed Deposit Account"
    },
    {
        value: "Other"
    }
];

const accountTypeTranslation = {
    "Saving Account": "savingAccount",
    "Current Account": "currentAccount",
    "Fixed Deposit Account": "fixedDepositAccount",
    Other: "other"
};

export const sortOrder = {
    SORT_BY_ASC: "ascend",
    SORT_BY_DESC: "descend",
    ASCENDING: "ASC",
    DESCENDING: "DESC"
};

const landingPages = {
    WorkshopRegistrationWizard: "WorkshopRegistrationWizard"
};
const roles = {
    workshopAdmin: "WorkshopAdmin",
    admin: "Admin"
};
const regionId = 1;
const downloadContractType = 4;
const contentType = 3;
const privacyPolicy = 5;

const customerTypeCodes = {
    Individual: 1,
    Company: 2
};

const ticketTypeCodes = {
    BODY_SHOP: 1,
    REGULAR: 2
};

const paymentLogType = {
    workshop: 1,
    recoveryTruck: 4,
    clearanceLevel: 7
};

const actionCodes = {
    ShowCustomerVehicles: 1000,
    VerifyCustomerOTP: 1001,
    RegenerateCustomerOTP: 1002,
    ViewCustomer: 1003,
    AddCustomerVehicle: 1004,
    ResendCustomerInvitation: 1007,
    DownloadServiceCard: 2000,
    SendServiceCardForApproval: 2001,
    EditServiceCard: 2002,
    ApproveServiceCard: 2003,
    RejectServiceCard: 2004,
    CompleteServiceCard: 2005,
    ViewServiceCard: 2006,
    StartJob: 2008,
    SendQuote: 2010,
    CompleteService: 3000,
    ViewService: 3001,
    AddJobCard: 3004,
    VerifyVehicleOTP: 4000,
    GenerateVehicleQR: 4001,
    CreateJobCard: 4002,
    RemoveVehicle: 4003,
    RegenerateVehicleOTP: 4004,
    ViewVehicle: 4005,
    ViewWorkshop: 5000,
    SendContract: 5001,
    ReviewWorkshop: 5002,
    RejectWorkshop: 5003,
    ApproveWorkshop: 5004,
    ResendContract: 5005,
    DeactivateWorkshop: 5006,
    ActivateWorkshop: 5007,
    EditWorkshopDetails: 5008,
    ExportWorkshop: 5009,
    ExportDocuSign: 5011,
    ActivateUser: 6000,
    DeactivateUser: 6001,
    EditUser: 6003,
    RemoveUser: 6002,
    ViewInvoice: 8000,
    ViewReferral: 210000,
    ViewAffliate: 210001,
    EditSupplier: 9001,
    ViewGovernor: 100000,
    ViewAmbassadors: 100001,
    ViewWorkshops: 100002,
    ViewRequisition: 110000,
    ApproveRequisition: 110001,
    RejectRequisition: 110002,
    ViewPart: 120000,
    EditRetailPrice: 120001,
    ViewSales: 130000,
    ViewTicket: 150000,
    CreateTicketCard: 150001,
    SendQuotation: 130001,
    Finalize: 130002,
    EditSales: 130003,
    DownloadSale: 130004,
    ViewTruck: 160000,
    LiveTracking: 160005,
    RejectTruck: 160001,
    ApproveTruck: 160002,
    ResendInvitation: 6004,
    editInvoice: 8001,
    ViewVatRate: 190000,
    EditVatRate: 190001,
    ViewVatRecoveryCharges: 180000,
    EditRecoveryCharges: 180001,
    ViewJourney: 170000,
    StartJourney: 170010,
    EditJourney: 170020,
    DeleteJourney: 170030,
    ViewPayouts: 200000
};

const permissionCodes = {
    SearchCustomer: 1000,
    ShowCustomer: 1001,
    ShowCustomers: 2000,
    ViewCustomer: 2001,
    AddCustomer: 2002,
    RemoveCustomer: 2003,
    UpdateCustomer: 2004,
    GenerateCustomerOtp: 2005,
    VerifyCustoemrOtp: 2006,
    ShowVehicles: 3000,
    ViewVehicle: 3001,
    AddVehicle: 3002,
    UpdateVehicle: 3003,
    RemoveVehicle: 3004,
    GenerateVehicleOtp: 3005,
    VerifyVehicleOtp: 3006,
    GenerateQRCode: 3007,
    ShowServiceRequests: 4000,
    ViewServiceRequest: 4001,
    ApproveServiceRequest: 4002,
    RejectServiceRequest: 4003,
    StartServiceRequest: 4004,
    CompleteServiceRequest: 4005,
    EditRetailPrice: 4006,
    EditSalePrice: 4007,
    EditCostPerHour: 4008,
    EditJobHours: 4009,
    DownloadInvoice: 5000,
    ShowWorkshops: 6000,
    ViewWorkshop: 6001,
    SendContract: 6002,
    ReSendContract: 6003,
    ReviewWorkshop: 6004,
    RejectWorkshop: 6005,
    ApproveWorkshop: 6006,
    ActivateWorkshop: 6007,
    DeactivateWorkshop: 6008,
    EditWorkshop: 6009,
    ExportWorkshopPdfProfile: 6010,
    ExportRegistrationSummaryPdf: 6011,
    ViewPermissions: 6012,
    EditPermissions: 6013,
    ShowEmployees: 7000,
    ViewEmployee: 7001,
    AddEmployee: 7002,
    RemoveEmployee: 7003,
    EditEmployee: 7004,
    EditEmployeeRole: 7005,
    ActivateEmployee: 7006,
    DeactivateEmployee: 7007,
    EditRole: 7008,
    ShowInventoryWorth: 8000,
    ShowRetailPrice: 8001,
    ShowOrders: 9000,
    ViewOrder: 9001,
    AddOrder: 9002,
    UpdateOrderStatus: 9003,
    AddInvoice: 9004
};

const userListingTypes = {
    LOOKUP: 400,
    LISTING: 200
};

const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const googleMapTheme = [
    {
        elementType: "geometry",
        stylers: [
            {
                color: "#f5f5f5"
            }
        ]
    },
    {
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off"
            }
        ]
    },
    {
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#616161"
            }
        ]
    },
    {
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: "#f5f5f5"
            }
        ]
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#bdbdbd"
            }
        ]
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                color: "#eeeeee"
            }
        ]
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#757575"
            }
        ]
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
            {
                color: "#e5e5e5"
            }
        ]
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e"
            }
        ]
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [
            {
                color: "#ffffff"
            }
        ]
    },
    {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#757575"
            }
        ]
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
            {
                color: "#dadada"
            }
        ]
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#616161"
            }
        ]
    },
    {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e"
            }
        ]
    },
    {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
            {
                color: "#e5e5e5"
            }
        ]
    },
    {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
            {
                color: "#eeeeee"
            }
        ]
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [
            {
                color: "#c9c9c9"
            }
        ]
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#9e9e9e"
            }
        ]
    }
];

const payoutLookupType = {
    workShops: 1,
    recoveryTruck: 4,
    ambassador: 7
};

export {
    paginationConstant,
    pageProperties,
    workshopStatus,
    workShopStatusCode,
    workShopUpdateStatusCode,
    workshopStatusFilterOptions,
    landingPages,
    regionId,
    downloadContractType,
    contentType,
    accountStatus,
    accountStatusCode,
    vehiclePaginationConstant,
    serviceRequestStatus,
    serviceRequestStatusCode,
    backendJobCodes,
    serviceCardStatus,
    serviceRequestStatusFilterOptions,
    serviceCardStatusCode,
    listingTypesMap,
    serviceOptionStatus,
    privacyPolicy,
    ServiceRequestFilteringStatus,
    stringLengths,
    InventoryStatus,
    vehicleStatus,
    vehicleStatusCode,
    journeyStatusCodes,
    serviceStatusFilterOptions,
    serviceCardStatusFilterOptions,
    roles,
    workshopCustomerPagination,
    workshopUserActionsStatus,
    workshopUserDesignationOption,
    workshopUserDesignationTranslation,
    workshopUserRoles,
    customerTypeCodes,
    actionCodes,
    permissionCodes,
    userListingTypes,
    bankAccountTypes,
    accountTypeTranslation,
    weekdays,
    ticketTypeCodes,
    recoveryTrucksFilterOptions,
    googleMapTheme,
    payoutLookupType,
    paymentLogType
};
