import firebase from "firebase/app";
import "firebase/messaging";

// also update in firebase-messaging-sw
const config = {
    apiKey: "AIzaSyDq2a4HgWb6qYhIMHip7HiZhHQU8EfpyIQ",
    authDomain: "hzm-spirits-of-speed.firebaseapp.com",
    projectId: "hzm-spirits-of-speed",
    storageBucket: "hzm-spirits-of-speed.appspot.com",
    messagingSenderId: "68407364055",
    appId: "1:68407364055:web:a60ef71b97b7ea0d882392",
    measurementId: "G-9JPLTZWXBX"
};

firebase.initializeApp(config);
export let messaging;

if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
}

export const requestFirebaseNotificationPermission = () =>
    new Promise((resolve, reject) => {
        if (messaging) {
            messaging
                .requestPermission()
                .then(() => {
                    console.log("GRANTED");
                    return messaging.getToken();
                })
                .then((firebaseToken) => {
                    resolve(firebaseToken);
                })
                .catch((err) => {
                    reject(err);
                });
            // messaging
            //     .getToken()
            //     .then((firebaseToken) => {
            //         resolve(firebaseToken);
            //     })
            //     .catch((err) => {
            //         reject(err);
            //     });
        } else reject("Not supported");
    });
