import antdIT from "antd/lib/locale-provider/it_IT";

const esItLang = {
    name: "Italiano", // Italian
    antdLocale: antdIT,
    i18nLocale: "it-IT",
    momentLocale: "es",
    direction: "ltr",
    icon: "it"
};
export default esItLang;
