import React, { useState } from "react";
import ReactJoyride from "react-joyride";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import TourContent from "./tour-content";
import { updateTourInfo, resetTour, skipTour } from "../../appRedux/slices/tour";

const Tour = (props) => {
    const { steps = [] } = props;
    const { t } = useTranslation("tour");

    const [helpers, setHelpers] = useState();
    // Will increment key in order to reset tour
    const [tourKey, setTourKey] = useState(0);

    const dispatch = useDispatch();
    const { showTour, step, skipped } = useSelector(({ tour }) => tour);

    const handleJoyrideCallback = (data) => {
        if (
            (data.action === "next" || data.action === "close") &&
            data.status === "running" &&
            data.lifecycle === "complete" &&
            data.type === "step:after"
        ) {
            // Reset and hide tour if close button pressed or if last step
            if (data.index + 1 === data.size || data.action === "close") {
                dispatch(resetTour());
            }
            // Else increment step
            else {
                dispatch(updateTourInfo({ step: data.index + 1 }));
            }
        }
        // Move to last step if skipped
        else if (data.action === "skip") {
            setTourKey(tourKey + 1);
            dispatch(skipTour());
        }
    };

    return (
        <ReactJoyride
            key={tourKey}
            className="TourContainer"
            getHelpers={setHelpers}
            run={showTour}
            stepIndex={Number(step) || 0}
            callback={handleJoyrideCallback}
            continuous
            disableOverlayClose
            showSkipButton
            hideBackButton
            showProgress={!skipped}
            steps={
                skipped
                    ? [
                          {
                              placementBeacon: "",
                              content: (
                                  <TourContent
                                      title={t("tourConclusionTitle")}
                                      content={<p>{t("tourConclusionContentOne")}</p>}
                                  />
                              ),
                              placement: "center",
                              target: "body",
                              disableBeacon: true
                          }
                      ]
                    : steps
            }
            disableScrolling={steps[step]?.disableScrolling}
            styles={{
                options: {
                    arrowColor: "#ffffffeb",
                    backgroundColor: "white",
                    overlayColor: "#0000002b",
                    primaryColor: "#000",
                    textColor: "black",
                    width: 900,
                    zIndex: 1000,
                    beaconSize: 30,
                    spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)"
                }
            }}
        />
    );
};

export default Tour;
