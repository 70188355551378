import enUsLang from "./entries/en-US";
import esArLang from "./entries/es-AR";
import esEsLang from "./entries/es-ES";
import esFrLang from "./entries/fr-FR";
import deDELang from "./entries/de-DE";
import esItLang from "./entries/it-IT";
import esUrLang from "./entries/es-UR";
import caESLang from "./entries/ca-ES";
import nlNLLang from "./entries/nl-NL";
import arAELang from "./entries/ar-AE";
import zhCNLang from "./entries/zh-CN";
import jaJPLang from "./entries/ja-JP";
import koKRLang from "./entries/ko-KR";
import ptPTLang from "./entries/pt-PT";
import ruRULang from "./entries/ru-RU";

const AppLocale = [
    {
        id: "ar-AE", // Arabic
        lang: arAELang
    },
    {
        id: "ca-ES", // Catalan
        lang: caESLang
    },
    {
        id: "zh-CN", // Chinese
        lang: zhCNLang
    },
    {
        id: "nl-NL", // Dutch
        lang: nlNLLang
    },
    {
        id: "en-US", // English
        lang: enUsLang
    },
    {
        id: "fr-FR", // French
        lang: esFrLang
    },
    {
        id: "de-DE", // German
        lang: deDELang
    },
    {
        id: "it-IT", // Italian
        lang: esItLang
    },
    {
        id: "ja-JP", // Japanese
        lang: jaJPLang
    },
    {
        id: "ko-KR", // Korean
        lang: koKRLang
    },
    {
        id: "pt-PT", // Portuguese
        lang: ptPTLang
    },
    // {
    //     id: "ru-RU", // Russian
    //     lang: ruRULang
    // },
    {
        id: "es-ES", // Spanish
        lang: esEsLang
    }
];

export default AppLocale;
