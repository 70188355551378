import ApiService from "../util/api.service";
import AuthService from "../util/auth.service";
import { SERVICE_CONFIG_URLS } from "./config";

const UserService = {
    updatePreferences,
    getPreferences
};

const userInfo = AuthService.getUserInfo();
const id = userInfo?.userId;

async function updatePreferences(payload) {
    const response = await ApiService.put(`${SERVICE_CONFIG_URLS.USER.CONTROLLER_NAME}/${id}${SERVICE_CONFIG_URLS.USER.PREFERENCES}`, payload);
    return response;
}

async function getPreferences(payload) {
    const response = await ApiService.get(`${SERVICE_CONFIG_URLS.USER.CONTROLLER_NAME}/${id}${SERVICE_CONFIG_URLS.USER.PREFERENCES}`);
    return response;
}

export default UserService;
