import { accountStatus } from "../constants/constant";
import { REGEX_PATTERNS } from "../util/constants/constant";

export const getAccountStatus = (code) => {
    for (const account in accountStatus) {
        if (accountStatus[account].Code === code) {
            return accountStatus[account];
        }
    }
    return {};
};

export const getRoute = (route, obj = {}) => {
    if (Object.keys(obj).length) {
        let objectKeys = Object.keys(obj);
        objectKeys.forEach((item) => {
            route = route.replace(
                new RegExp(REGEX_PATTERNS.DYNAMIC_PARAMETER_CHECK_IN_ROUTES, "i"),
                (match) => {
                    let formattedMatchedValue = match.slice(1);
                    return obj[formattedMatchedValue];
                }
            );
        });
        return route;
    }
    return route;
};
