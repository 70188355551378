import { Layout } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../routes";
import { STORAGE_CONST } from "../../util/constants/constant";
import Tour from "../tour";
import { TOUR_STEP, TOUR } from "../tour/constant";
const { Content } = Layout;

const MainApp = (props) => {
    const userInfo = JSON.parse(localStorage[STORAGE_CONST.USER_INFO]);
    const dispatch = useDispatch();

    return (
        <>
            <Layout className="gx-app-layout">
                <Layout className="padding-0">
                    {/* {showTour &&
                        (!userInfo?.hasInventory ||
                            !userInfo?.hasOtherUsers ||
                            !userInfo?.hasJobCards ||
                            !isFinished) && <Tour run={true} />} */}
                    <Content className="padding-0">
                        <Routes />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};
export default MainApp;
