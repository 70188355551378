export const APP_NAME = "Spirits Of Speed";

export const SIGNUP_STEP_HEADINGS = {
    COMPANY_INFO: "Company Information",
    COMPANY_DETAILS: "Company Details",
    REPRESENTATIVE_INFO: "Representative Information",
    LEADERSHIP_INFO: "Leadership Information",
    PLAN_SELECTION: "Choose Your Plan",
    ACCOUNT_ACTIVATION: "Account Activation",
    UNDER_REVIEW: ""
};

export const REGEX_PATTERNS = {
    URL_PATTERNS:
        "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$",
    DYNAMIC_PARAMETER_CHECK_IN_ROUTES: /:([\d\w])+/
};

export const SIGNUP_STEPS = {
    COMPANY_INFO: "COMPANY_INFO",
    COMPANY_DETAILS: "COMPANY_DETAILS",
    REPRESENTATIVE_INFO: "REPRESENTATIVE_INFO",
    LEADERSHIP_INFO: "LEADERSHIP_INFO",
    PLAN_SELECTION: "PLAN_SELECTION",
    ACCOUNT_ACTIVATION: "ACCOUNT_ACTIVATION",
    UNDER_REVIEW: "UNDER_REVIEW"
};
export const SIGNUP_STEPS_STEPPER = {
    COMPANY_INFO: 0,
    COMPANY_DETAILS: 1,
    REPRESENTATIVE_INFO: 2,
    LEADERSHIP_INFO: 2,
    PLAN_SELECTION: 3,
    ACCOUNT_ACTIVATION: 4
};

export const REG_STATES_CONST = {
    EmailVerified: "EmailVerified",
    CompanyInformationUpdated: "CompanyInformationUpdated",
    CompanyDetailsUpdated: "CompanyDetailsUpdated",
    LeadershipDetailsUpdated: "LeadershipDetailsUpdated",
    PlanSelectionUpdated: "PlanSelectionUpdated",
    BankAccountLinked: "BankAccountLinked",
    Invalid: "Invalid",
    PendingEmailVerification: "PendingEmailVerification",
    PendingAdminReview: "PendingAdminReview",
    PendingStripeReview: "PendingStripeReview",
    Active: "Active",
    Inactive: "Inactive",
    Rejected: "Rejected",
    MissingRow: "MissingRow"
};

export const REG_STATES = [
    // coming from server
    "EmailVerified",
    "CompanyInformationUpdated",
    "CompanyDetailsUpdated",
    "LeadershipDetailsUpdated",
    "PlanSelectionUpdated",
    "BankAccountLinked",
    "Invalid",
    "PendingEmailVerification",
    "PendingAdminReview",
    "PendingStripeReview",
    "Active",
    "Inactive",
    "Rejected",
    "MissingRow"
];

export const REDIRECT_SIGN_UP_STEPS_STATES = [
    "EmailVerified",
    "CompanyInformationUpdated",
    "CompanyDetailsUpdated",
    "LeadershipDetailsUpdated",
    "PlanSelectionUpdated",
    "BankAccountLinked",
    "PendingEmailVerification",
    "PendingAdminReview",
    "PendingStripeReview"
];

export const REGISTRATION_STATES = {
    [REG_STATES[0]]: SIGNUP_STEPS.COMPANY_INFO,
    [REG_STATES[1]]: SIGNUP_STEPS.COMPANY_DETAILS,
    [REG_STATES[2]]: [SIGNUP_STEPS.REPRESENTATIVE_INFO, SIGNUP_STEPS.LEADERSHIP_INFO],
    [REG_STATES[3]]: SIGNUP_STEPS.PLAN_SELECTION,
    [REG_STATES[4]]: SIGNUP_STEPS.ACCOUNT_ACTIVATION,
    [REG_STATES[5]]: SIGNUP_STEPS.UNDER_REVIEW
};

export const ROLES = {
    Admin: "Admin",
    BusinessAdmin: "BusinessAdmin"
};

export const sortOrder = {
    SORT_BY_ASC: "ascend",
    SORT_BY_DESC: "descend",
    ASCENDING: "ASC",
    DESCENDING: "DESC"
};

export const STORAGE_CONST = {
    USER_INFO: "USER_INFO",
    USER_CURRENCY_PREFIX: "CurrencyPrefix",
    USER_LANGUAGE: "Language",
    NEW_MESSAGE: "NEW_MESSAGE"
};

export const DEFAULT_LANGUAGE = "en-US";
