import { getRoute } from "../common/index";

export const baseRoutes = {
    LOGIN: "login",
    SIGN_UP: "signup",
    REMOVE_SUSPENSION: "remove-suspension",
    RESET_PASSWORD: "reset-password",
    FORGET_PASSWORD: "forget-password",
    VERIFY_EMAIL: "verify-email",
    EMAIL_VERIFIED: "email-verified",
    SCAN_QR_CODE: "scan-qr-code",
    TERMS_CONDITIONS: "terms-conditions",
    WS_TERMS_CONDITIONS: "workshop",
    USER_TERMS_CONDITIONS: "user",
    DASHBOARD: "dashboard",
    WORKSHOP_CUSTOMERS: "customers",
    WORKSHOP_SERVICES: "services",
    WORKSHOP_SERVICE_REQUESTS: "service-requests",
    CUSTOMER_VEHICLES: "vehicles",
    WORKSHOP_INVENTORY: "workshop-inventory",
    ADD_INVOICE: "add-invoice",
    ADD_INVENTORY: "add-inventory",
    EDIT_INVENTORY: "edit-invoice",
    WORKSHOP_ONBOARD_WIZARD: "workshop-onboarding-wizard",
    REQUEST_RECEIVED: "request-received",
    REQUEST_REJECTED: "request-rejected",
    CUSTOMER_PROFILE: "customer-profile",
    INVOICE_DETAIL: "invoice-detail",
    REQUISITION_DETAIL: "requisition-detail",
    PART_DETAIL: "part-detail",
    SERVICE_PROFILE: "service-profile",
    WORKSHOP_PROFILE: "workshop-profile",
    VEHICLE_DETAILS: "vehicle-details",
    PENDING_EMAIL_VERIFICATION: "pending-email-verification",
    PENDING_OTP_VERIFICATION: "pending-otp-verification",
    CREATE_JOB_CARD: "job-card",
    ADMIN_DASHBOARD: "admin-dashboard",
    WORKSHOP_APPROVAL: "workshop-approval",
    PERMISSIONS: "permissions",
    PROFILE_SETTING: "setting",
    REFERRAL_DETAIL: "referrals",
    AFFLIATE_DETAIL: "referrals/profile",
    AMBASSADOR_PROFILE: "ambassador",
    ADD_REQUISITION: "add-requisition",
    APPROVE_REQUISITION: "approve-requisition",
    ADD_JOB_CARD: "add-job-card",
    QUICK_JOB_CARD: "quick-job-card",
    APPROVE_JOB_CARD: "edit-job-card",
    EMPLOYEES: "employees",
    COUNTER_SALES: "counter-sales",
    COUNTER_SALE_DETAILS: "counter-sale-details",
    ADD_COUNTER_SALE: "add-counter-sale",
    EDIT_COUNTER_SALE: "edit-counter-sale",
    PART_SUPPLIER: "part-suppliers",
    RECOVERY_TRUCK: "recovery-trucks",
    INSURANCE: "insurance",
    TICKET: "ticket",
    MONTHLY_TRANSACTION: "monthly-transaction-details",
    WALLET: "wallet"
};

export const unAuthenticatedRoutes = {
    LOGIN: "/login",
    SIGN_UP: "/signup",
    REMOVE_SUSPENSION: "/:culture/remove-suspension/:resetToken",
    RESET_PASSWORD: "/:culture/reset-password/:resetToken",
    FORGET_PASSWORD: "/forget-password",
    VERIFY_EMAIL: "/verify-email",
    EMAIL_VERIFIED: "/email-verified",
    SCAN_QR_CODE: "/scan-qr-code",
    PENDING_OTP_VERIFICATION: "/pending-otp-verification",
    PENDING_OTP_VERIFICATION_CULTURE: "/:culture/pending-otp-verification",
    TERMS_CONDITIONS: "/:id/terms-conditions"
};

export const commonRoutes = {
    CONSENT_SUCCESS: "/:culture/consent-success/:id/:otp",
    WS_TERMS_CONDITIONS: "/:culture/:id/terms-conditions/workshop",
    USER_TERMS_CONDITIONS: "/:culture/:id/terms-conditions/user"
};

export const authenticatedRoutes = {
    WORKSHOP_ADMIN: {
        DASHBOARD: "/dashboard",
        WORKSHOP_CUSTOMERS: "/customers",
        WORKSHOP_SERVICES: "/services",
        WORKSHOP_SERVICE_REQUESTS: "/service-requests",
        CUSTOMER_VEHICLES: "/vehicles",
        WORKSHOP_INVENTORY: "/workshop-inventory",
        ADD_INVOICE: "/add-invoice",
        ADD_INVENTORY: "/add-inventory",
        EDIT_INVENTORY: "/edit-invoice/:id",
        WORKSHOP_ONBOARD_WIZARD: "/workshop-onboarding-wizard",
        REQUEST_RECEIVED: "/request-received",
        REQUEST_REJECTED: "/request-rejected",
        CUSTOMER_PROFILE: "/customer-profile/:id",
        INVOICE_DETAIL: "/invoice-detail/:id",
        REQUISITION_DETAIL: "/requisition-detail/:id",
        SERVICE_PROFILE: "/service-profile/:id",
        WORKSHOP_PROFILE: "/workshop-profile",
        PERMISSIONS: "/permissions",
        VEHICLE_DETAILS: "/vehicle-details/:id",
        SCAN_QR_CODE: "/scan-qr-code",
        PENDING_EMAIL_VERIFICATION: "/pending-email-verification",
        EMAIL_VERIFIED: "/email-verified",
        PENDING_OTP_VERIFICATION: "/pending-otp-verification",
        PENDING_OTP_VERIFICATION_CULTURE: "/:culture/pending-otp-verification",
        CREATE_JOB_CARD: "/dashboard/job-card",
        QUICK_JOB_CARD: "/quick-job-card",
        QUICK_JOB_CARD_TICKET: "/quick-job-card/ticket/:ticketId",
        ADD_JOB_CARD: "/add-job-card/:serviceRequestId",
        APPROVE_JOB_CARD: "/edit-job-card/:id",
        PROFILE_SETTING: "/setting",
        ADD_REQUISITION: "/add-requisition",
        APPROVE_REQUISITION: "/approve-requisition/:id",
        PART_DETAIL: "/part-detail/:id",
        EMPLOYEES: "/employees",
        COUNTER_SALES: "/counter-sales",
        COUNTER_SALE_DETAILS: "/counter-sale-details/:id",
        ADD_COUNTER_SALE: "/add-counter-sale",
        EDIT_COUNTER_SALE: "/edit-counter-sale/:id",
        PART_SUPPLIER: "/part-suppliers",
        RECOVERY_TRUCK: "/recovery-trucks",
        LIVE_TRACKING: "/live-tracking",
        RECOVERY_TRUCK_PROFILE: "/recovery-trucks/:id",
        INSURANCE: "/insurance",
        WALLET: "/wallet",
        CHAT: "/chat"
    },
    SYSTEM_ADMIN: {
        WORKSHOP_APPROVAL: "/admin-dashboard",
        WORKSHOP_PROFILE: "/workshop-approval/:id",
        CUSTOMER_VEHICLES: "/vehicles",
        VEHICLE_DETAILS: "/vehicle-details/:id",
        REFERRAL_LISTING: "/referrals",
        REFERRAL_DETAILS: "/referrals/:id",
        REFERRAL_DETAILS_PROFILE: "/referrals/profile/:id/:type",
        AMBASSADOR_PROFILE: "/ambassador/:id",
        RECOVERY_TRUCK: "/recovery-trucks",
        RECOVERY_TRUCK_PROFILE: "/recovery-trucks/:id",
        WORKSHOP_CUSTOMERS: "/customers",
        CUSTOMER_PROFILE: "/customer-profile/:id",
        WORKSHOP_SERVICES: "/services",
        SERVICE_PROFILE: "/service-profile/:id",
        COUNTER_SALE_DETAILS: "/counter-sale-details/:id",
        REGIONAL_SETTING: "/regional-setting",
        PAYMENT_LOG: "/payment-log",
        MONTHLY_TRANSACTION: "/monthly-transaction-details/:id",
        PERMISSIONS: "/permissions"
    },
    GOVERNOR: {
        DASHBOARD: "/dashboard",
        AMBASSADOR_PROFILE: "/ambassador/:id",
        WORKSHOP_PROFILE: "/workshop-approval/:id"
    },
    AMBASSADOR: {
        DASHBOARD: "/referrals/:id",
        REFERRAL_DETAILS_PROFILE: "/referrals/profile/:id/:type"
    }
};

const nonDashboardRoutes = [
    authenticatedRoutes.WORKSHOP_ADMIN.SCAN_QR_CODE,
    authenticatedRoutes.WORKSHOP_ADMIN.PENDING_EMAIL_VERIFICATION,
    authenticatedRoutes.WORKSHOP_ADMIN.EMAIL_VERIFIED,
    authenticatedRoutes.WORKSHOP_ADMIN.PENDING_OTP_VERIFICATION
];

export const redirect = (history, location, info) => {
    const landingPage = info?.landingPage;
    const workshopPrivacyPolicy = getRoute(commonRoutes.WS_TERMS_CONDITIONS, {
        id: "",
        culture: ""
    }).replace("//", "");
    const userPivacyPolicy = getRoute(commonRoutes.WS_TERMS_CONDITIONS, {
        id: "",
        culture: ""
    }).replace("//", "");
    if (
        `/${landingPage}` !== authenticatedRoutes.WORKSHOP_ADMIN.DASHBOARD &&
        location.search &&
        location.pathname === authenticatedRoutes.WORKSHOP_ADMIN.PENDING_OTP_VERIFICATION
    ) {
        return history.push(
            `${authenticatedRoutes.WORKSHOP_ADMIN.PENDING_OTP_VERIFICATION}${location.search}`
        );
    }
    if (
        `/${landingPage}` === authenticatedRoutes.WORKSHOP_ADMIN.DASHBOARD &&
        location.search &&
        location.pathname === authenticatedRoutes.WORKSHOP_ADMIN.CREATE_JOB_CARD
    ) {
        return history.push(
            `${authenticatedRoutes.WORKSHOP_ADMIN.CREATE_JOB_CARD}${location.search}`
        );
    } else if (
        `/${landingPage}` === authenticatedRoutes.WORKSHOP_ADMIN.WORKSHOP_ONBOARD_WIZARD &&
        (location?.pathname.includes(workshopPrivacyPolicy) ||
            location?.pathname.includes(userPivacyPolicy))
    ) {
        return history.push(location.pathname);
    } else if (
        `/${landingPage}` === authenticatedRoutes.WORKSHOP_ADMIN.DASHBOARD &&
        nonDashboardRoutes.includes(history?.location?.pathname)
    ) {
        return history.push(authenticatedRoutes.WORKSHOP_ADMIN.DASHBOARD);
    } else if (
        (location?.pathname?.includes(workshopPrivacyPolicy) ||
            location?.pathname?.includes(userPivacyPolicy)) &&
        !nonDashboardRoutes.includes(history?.location?.pathname)
    ) {
        return history.push(location?.pathname);
    } else {
        return history.push(landingPage);
    }
};

export const roles = {
    WORKSHOP_SUPER_ADMIN: "WorkshopSuperAdmin",
    WORKSHOP_ADMIN: "WorkshopAdmin",
    SYSTEM_ADMIN: "SystemAdmin",
    WORKSHOP_USER: "WorkshopUser",
    INVENTORY_MANAGER: "InventoryManager",
    GOVERNOR: "Governor",
    AMBASSADOR: "Ambassador"
};

export const landingPages = {
    [roles.WORKSHOP_ADMIN]: {
        DASHBOARD: "dashboard",
        ONBOARDING: "workshop-onboarding-wizard",
        REQUEST_RECEIVED: "request-received",
        REQUEST_REJECTED: "request-rejected",
        PENDING_OTP_VERIFICATION: "pending-otp-verification",
        PENDING_EMAIL_VERIFICATION: "pending-email-verification"
    },
    [roles.SYSTEM_ADMIN]: {
        DASHBOARD: "admin-dashboard"
    },
    [roles.GOVERNOR]: {
        DASHBOARD: "dashboard"
    },
    [roles.AMBASSADOR]: {
        DASHBOARD: "dashboard"
    },
    [roles.WORKSHOP_USER]: {
        DASHBOARD: "dashboard"
    }
};

export function getDefaultLandingPage(role) {
    return Object.values(landingPages[role])[0];
}
