import ApiService from "../util/api.service";
import AuthService from "../util/auth.service";
import { SERVICE_CONFIG_URLS, multiFormDataHeader } from "./config";
import { STORAGE_CONST } from "../util/constants/constant";

const userInfo = AuthService.getUserInfo();
const regionId = userInfo?.regionId;
const RegistrationService = {
    registerWorkshop,
    updateWorkshop,
    getContractInfo,
    uploadWorkshopDocument,
    getBrands,
    addCustomer,
    update,
    verifyOtp,
    resendOtp
};

async function registerWorkshop(payload) {
    const response = await ApiService.put(SERVICE_CONFIG_URLS.REGISTRATION.WORKSHOP, payload);
    return response;
}

async function updateWorkshop(payload, id) {
    const url = `${SERVICE_CONFIG_URLS.REGISTRATION.WORKSHOP}/${id}`;
    const response = await ApiService.patch(url, payload);
    return response;
}

async function getContractInfo(contractType, config, id) {
    const url = `/registration/region/${
        id ?? regionId ?? JSON.parse(localStorage[STORAGE_CONST.USER_INFO]).regionId
    }/contract/${contractType}/download`;
    const response = await ApiService.get(url, null, config);
    return response;
}

async function uploadWorkshopDocument(payload, id) {
    const url = `/registration/workshops/${id}/upload`;
    const response = await ApiService.post(url, payload, multiFormDataHeader);
    return response;
}

async function getBrands() {
    const url = `/registration/${
        regionId ?? JSON.parse(localStorage[STORAGE_CONST.USER_INFO]).regionId
    }/brands`;
    const response = await ApiService.get(url);
    return response;
}

async function addCustomer(payload) {
    const response = await ApiService.put(SERVICE_CONFIG_URLS.REGISTRATION.CUSTOMER, payload);
    return response;
}

async function update(id, payload) {
    const url = `/registration/workshops/${id}/review`;
    const response = await ApiService.put(url, payload);
    return response;
}

async function verifyOtp(payload) {
    const url = `${SERVICE_CONFIG_URLS.REGISTRATION.WORKSHOP_OTP}`;
    const response = await ApiService.post(url, payload);
    return response;
}

async function resendOtp() {
    const url = `${SERVICE_CONFIG_URLS.REGISTRATION.WORKSHOP_OTP}`;
    const response = await ApiService.patch(url);
    return response;
}

export default RegistrationService;
