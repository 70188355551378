import React from "react";
import { Row, Col, Button } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import sosLogo from "../../assets/images/logo-sos-v-2.png";
import { authenticatedRoutes, unAuthenticatedRoutes } from "../../routes/constant";
import LocalStorageService from "../../util/local-storage.service";
import { STORAGE_CONST } from "../../util/constants/constant";
import utilService from "../../util/utils.service";
import "./styles.less";

const DetailedSideBar = (props) => {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation(["footer", "login", "signup"]);

    async function Logout() {
        LocalStorageService.remove(STORAGE_CONST.USER_INFO);
        return utilService.redirectToLogin();
    }

    const showButton = () => {
        if (location.pathname === unAuthenticatedRoutes.SIGN_UP) {
            return (
                <Button
                    type="link"
                    className="sidebar-btn siderbar-dark-btn"
                    size="large"
                    onClick={() => history.push(unAuthenticatedRoutes.LOGIN)}
                >
                    {t("login:login")}
                </Button>
            );
        } else if (location.pathname === unAuthenticatedRoutes.LOGIN) {
            return (
                <Button
                    type="link"
                    className="sidebar-btn siderbar-dark-btn"
                    size="large"
                    onClick={() => history.push(unAuthenticatedRoutes.SIGN_UP)}
                >
                    {t("signup:signUp")}
                </Button>
            );
        } else if (location.pathname === authenticatedRoutes.WORKSHOP_ADMIN.REQUEST_RECEIVED || location.pathname === authenticatedRoutes.WORKSHOP_ADMIN.PENDING_EMAIL_VERIFICATION) {
            return (
                <Button
                    type="link"
                    className="sidebar-btn siderbar-dark-btn width-auto preserve-padding"
                    size="large"
                    onClick={Logout}
                >
                    {t("login:backToLogin")}
                </Button>
            );
        }
    };

    return (
        <Row justify="center" className="height-100 auth-side-screen-main-container fixed-col">
            <Col flex="1">
                <div className="auth-side-screen-wrap">
                    <div className="auth-side-screen-container">
                        <div className="auth-side-screen-content">
                            <Row justify="center" gutter={[0, 50]} className="margin-0">
                                <Col>
                                    <img alt="Speed of Speeds Logo" src={sosLogo} />
                                </Col>
                                <Col>
                                    {props.sideContent ? (
                                        props.sideContent
                                    ) : (
                                        <p className="auth-side-screen-description">
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the
                                            industry's standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and
                                            scrambled it to make a type specimen book.
                                        </p>
                                    )}
                                </Col>
                                <Col>{showButton()}</Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Col>
            <Col className="auth-side-screen-footer">
                <div>{t("footer:copyrightText")}</div>
            </Col>
        </Row>
    );
};

export default DetailedSideBar;
