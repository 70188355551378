// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";

//Regex  const
export const Patterns = {
    emailPattern: '/^([^<>()[].,;:s@"]{6,30})@(([^<>()[].,;:s@"]+.)+[^<>()[].,;:s@"]{2,})$/g',
    passwordPattern3:
        "^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{8,16}$",
    passwordPattern: "^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9]).{8,}$",
    vinNumber: "^[a-zA-Z0-9]{5,17}$",
    vin_not_O_I_Q: /^[^oiq]+$/i,
    registrationNo: "^[A-Za-z0-9-]*$",
    number: "^[0-9]*$",
    notNumberOnly: "\\D",
    notZeroOnly: /[^0\D]/,
    phoneNumberPatterns: "^([(][0-9]{3}[)] )[0-9]{3}-[0-9]{4}$",
    urlPattern:
        "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$",
    alphaNumericHyphen: "^([a-zA-Z0-9- ]+)$",
    alphaNumeric: "^([a-zA-Z0-9 ]+)$",
    alphaNumericWithAccents: /^[a-zA-Z0-9\p{L}\- ]+$/u,
    alphaNumericNoSpace: "^([a-zA-Z0-9]+)$",
    alphaNumericWithSpecChar: "^[ A-Za-z0-9_@./#&+-]*$",
    uuidIdentifier: "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}",
    extractCurrencyCode: /[\d,.]/g,
    thousandSeparator: /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
    identifierParamIds: /:\w+/g
};
