import React from "react";
import { Spin } from "antd";

function FullPageLoader() {
    return (
        <React.Fragment>
            <div className="loader loading">
                <Spin />
                <h5 className="loadingText">Please wait ...</h5>
            </div>
        </React.Fragment>
    );
}

export default FullPageLoader;
