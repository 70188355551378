import { createSlice, createAction } from "@reduxjs/toolkit";

const chatSlice = createSlice({
    name: "chat",
    initialState: {
        unreadCount: 0,
        allChatList: [],
        selectedChatUser: {},
        loading: true
    },
    reducers: {
        getChats: (state, action) => {
            const unreadCount = action.payload.allChatList.reduce(
                (acc, cur) => acc + cur.unreadCount,
                0
            );
            return {
                ...state,
                ...action.payload,
                unreadCount: unreadCount
            };
        },
        selectChatUser: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        }
    }
});

export const { getChats, selectChatUser } = chatSlice.actions;
export default chatSlice.reducer;
