import { Popover } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import AppLocale from "../../languageProvider/index";
import authService from "../../util/auth.service";
import utilService from "../../util/utils.service";
import translationService from "../../util/translation.service";
import userService from "../../services/user.service";
import "./index.less";

const Language = (props) => {
    const { defaultVisible = false, onLanguageSelect = () => {} } = props;
    const { locale } = useSelector(({ settings }) => settings);
    const [visible, setVisible] = useState(defaultVisible);
    const currentLocale = utilService.getLocaleData(locale);

    const handleLanguageSelect = async (language) => {
        setVisible(false);
        if (authService.isTokenExist()) {
            const { ok, data, response } = await userService.updatePreferences({
                language: language.id
            });
            if (ok) {
                translationService.updateLocale(language.id);
                onLanguageSelect(language);
                window.location.reload();
            }
        }
        else {
            translationService.updateLocale(language.id);
            onLanguageSelect(language);
            // window.location.reload();
        }
    }

    const languageMenu = () => (
        <CustomScrollbars className="gx-popover-lang-scroll">
            <ul className="gx-sub-popover">
                {AppLocale.map((x) => (
                    <li
                        className="gx-media gx-pointer flex-row-center"
                        key={x.id}
                        onClick={(e) => handleLanguageSelect(x)}
                    >
                        {/* <i className={`flag flag-24 gx-mr-2 flag-${x.lang.icon}`} /> */}
                        <span className="gx-language-text">{x.lang.name}</span>
                    </li>
                ))}
            </ul>
        </CustomScrollbars>
    );
    
    return (
      <Popover
          overlayClassName="gx-popover-horizantal"
          placement="bottomRight"
          content={languageMenu()}
          trigger="click"
          visible={visible}
          onVisibleChange={(visible) => setVisible(visible)}
      >
          <span
              className="gx-pointer gx-flex-row gx-align-items-center color-white"
          >
              <img src={require("assets/images/language-icon.png")} />
              <span className="gx-pl-2 gx-language-name">
                  {currentLocale.lang.name}
              </span>
              <i className="icon icon-chevron-down gx-pl-2" />
          </span>
      </Popover>
    );
};

export default Language;
