import antdCA from "antd/lib/locale-provider/ca_ES";

const caESLang = {
    name: "Català", // Catalan
    antdLocale: antdCA,
    i18nLocale: "ca-ES",
    momentLocale: "es",
    direction: "ltr",
    icon: "catalonia"
};
export default caESLang;
