import React, { useState, useEffect } from "react";
import QrReader from "react-qr-reader";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { ConsoleSqlOutlined, LoadingOutlined } from "@ant-design/icons";
import { isBrowser, isMobile } from "react-device-detect";
import { Row, Col, Form, Input, Spin, Divider } from "antd";

import { authenticatedRoutes } from "../../routes/constant";
import notificationService from "../../util/notification.service";
import LookupService from "../../services/lookup.service";
import "./index.less";

const ScanQRApp = (props) => {
    const history = useHistory();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();
    const FormItem = Form.Item;
    const [form] = Form.useForm();
    const [vehicleInfo, setVehicleInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const loadingIcon = <LoadingOutlined spin className="email-verified-loading-icon" />;
    const workshopId = query.get("workshopId");

    // useEffect(() => {
    //     if (isBrowser) {
    //         return history.push(authenticatedRoutes.DASHBOARD);
    //     }
    // }, []);

    useEffect(() => {
        if (Object.keys(vehicleInfo).length > 0) {
            form.setFieldsValue({
                vin: vehicleInfo?.vin,
                registrationNo: vehicleInfo?.registrationNo,
                brand: vehicleInfo?.brand,
                model: vehicleInfo?.model,
                vehicleType: vehicleInfo?.vehicleCategory,
                year: vehicleInfo?.year
            });
        }
    }, [vehicleInfo]);

    const handleScan = (data) => {
        if (data) {
            const parseScanCode = JSON.parse(`{${`${data}`}}`);
            if (parseScanCode.hasOwnProperty("id")) {
                getVehicleInfo(parseScanCode.id);
            }
        }
    };
    const handleError = (err) => {};

    const getVehicleInfo = async (vin) => {
        setIsLoading(true);
        let { ok, data, response } = await LookupService.getVehicleInfo({
            workshopId,
            vin
        });
        if (ok) {
            setVehicleInfo(data);
            notificationService.success(
                "Scan Vehicle details have been successfully sent to dashboard",
                "",
                "bottomRight"
            );
        } else {
            notificationService.error(response.message, "", "bottomRight");
        }
        setIsLoading(false);
    };

    if (isLoading) {
        return (
            <Row align="middle" justify="center" className="height-auto-min-100 margin-0">
                <Col>
                    <Spin indicator={loadingIcon} size="large" className="loader"></Spin>
                </Col>
            </Row>
        );
    }

    return (
        <>
            <Row type="flex" justify="center" align="middle" className="mtb-30">
                {isBrowser && !Object.keys(vehicleInfo).length && (
                    <>
                        <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24} className="mb-10">
                            <Row type="flex" justify="center">
                                <Col>
                                    <h1
                                        className="mb-10"
                                        style={{ color: "white", textAlign: "center" }}
                                    >
                                        <b>Scan Me</b>
                                    </h1>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={24} xs={24} md={8} lg={8} xl={8} xxl={8} className="mb-40">
                            <QrReader
                                delay={300}
                                onError={handleError}
                                onScan={handleScan}
                                style={{ width: "100%", boxShadow: "1px 3px 1px grey" }}
                                className="scan-qr"
                            />
                        </Col>
                        <Row>
                            <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                <ol>
                                    <li className="white-text">
                                        Look for a symbol like the one above on the Customer's car
                                        over the :
                                        <br />
                                        <br />
                                        <b>Windsheild or Rear Windsheild</b>
                                        <br />
                                        <br />
                                    </li>
                                    <li className="white-text">
                                        Try to keep the Phone steady over the QR Code as it
                                        automatically scans the necessary data.
                                    </li>
                                </ol>
                            </Col>
                        </Row>
                    </>
                )}
            </Row>
            {/* <Row> */}
            {Object.keys(vehicleInfo).length && (
                <Form
                    form={form}
                    layout="vertical"
                    name="add-vehicle-form"
                    className="white-label-form margin-0"
                >
                    <Row
                        gutter={[0, 0]}
                        type="flex"
                        justify="center"
                        className="inputs-horizontal margin-0"
                    >
                        <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24} className="mb-10">
                            <Row type="flex" justify="center">
                                <Col>
                                    <h1 style={{ color: "white", textAlign: "center" }}>
                                        <b>Vehicle Info</b>
                                    </h1>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <FormItem
                                required={false}
                                label={`VIN`}
                                name="vin"
                                validateTrigger="onBlur"
                                layout="vertical"
                            >
                                <Input size="large" disabled></Input>
                            </FormItem>
                        </Col>
                        <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <FormItem
                                required={false}
                                label={`Registration Number`}
                                name="registrationNo"
                                validateTrigger="onBlur"
                                layout="vertical"
                            >
                                <Input maxLength={50} size="large" disabled></Input>
                            </FormItem>
                        </Col>

                        <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                            <FormItem
                                required={false}
                                label={`Brand`}
                                name="brand"
                                validateTrigger="onBlur"
                                layout="vertical"
                            >
                                <Input maxLength={50} size="large" disabled></Input>
                            </FormItem>
                        </Col>

                        <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                            <FormItem
                                required={false}
                                label={`Model`}
                                name="model"
                                validateTrigger="onBlur"
                                layout="vertical"
                            >
                                <Input size="large" disabled></Input>
                            </FormItem>
                        </Col>

                        <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                            <FormItem
                                required={false}
                                label={`Type`}
                                name="vehicleType"
                                validateTrigger="onBlur"
                                layout="vertical"
                            >
                                <Input size="large" disabled></Input>
                            </FormItem>
                        </Col>

                        <Col sm={24} xs={24} md={12} lg={12} xl={12} xxl={12}>
                            <FormItem
                                required={false}
                                label={`Year`}
                                name="year"
                                validateTrigger="onBlur"
                                layout="vertical"
                                class="date-picker"
                            >
                                <Input size="large" disabled></Input>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            )}
        </>
    );
};

export default ScanQRApp;
