import React from "react";
import { Row, Col } from "antd";
import DetailedSideBar from "../../components/detailed-side-bar";

const SideBarWizardScreen = (props) => {
    return (
        <>
            <Row className="wizard-screen-row">
                <Col
                    xxl={7}
                    xl={7}
                    lg={7}
                    md={0}
                    sm={0}
                    xs={0}
                    className="height-auto-min-100 margin-0 padding-0"
                >
                    <DetailedSideBar sideContent={props.sideContent} />
                </Col>
                <Col
                    xxl={17}
                    xl={17}
                    lg={17}
                    md={24}
                    sm={24}
                    xs={24}
                    className="height-auto-min-100 margin-0 padding-0 wizard-background-image"
                >
                    {props.children}
                </Col>
            </Row>
        </>
    );
};

export default SideBarWizardScreen;
