import { STORAGE_CONST } from './constants/constant';
import LocalStorageService from './local-storage.service'

const AuthService = {
  isTokenExist,
  getToken,
  getUserInfo,
  getRole,
  getPreferences,
  getLandingPage,
  setPreferences
}

function getRole() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    return userInfo.roles[0];
  }
  return null;
}

function isTokenExist() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    return true;
  }
  return false;
}

function getToken() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    return userInfo.token;
  }
  return null;
}

function getUserInfo() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    return JSON.parse(userInfo);
  }
  return null;
}

function getPreferences(key) {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    return key ? userInfo.preferences?.find(preference => preference?.key === key) : userInfo.preferences;
  }
  return null;
}

function getLandingPage() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    return userInfo.landingPage;
  }
  return null;
}

function setPreferences(key, value) {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);

    if (key) {
      const preference = userInfo.preferences.find(preference => preference.key === key);
      
      if (preference) preference.value = value;
      else userInfo.preferences.push({ key, value })
    }
    else userInfo.preferences = value;

    LocalStorageService.set(STORAGE_CONST.USER_INFO, JSON.stringify(userInfo));
  }
}

export default AuthService
