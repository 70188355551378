import { createSlice, createAction } from "@reduxjs/toolkit";

const locationChange = createAction('@@router/LOCATION_CHANGE');

const INITIAL_STATE = {
    showTour: false,
    step: 0,
    active: false,
    skipped: false
}

const tourSlice = createSlice({
    name: "tour",
    initialState: INITIAL_STATE,
    reducers: {
        updateTourInfo: (state, action) => ({
            ...state,
            ...action.payload
        }),
        resetTour: (state, action) => INITIAL_STATE,
        skipTour: (state, action) => ({
            skipped: true,
            step: 0,
        })
    },
    extraReducers: builder => {
        builder
          .addCase(locationChange, (state, action) => INITIAL_STATE)
          .addDefaultCase(() => { })
      },
});

export const { updateTourInfo, resetTour, skipTour } = tourSlice.actions;
export default tourSlice.reducer;
