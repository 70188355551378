const authControllerName = "/Auth";
const registrationControllerName = "/registration";
const workshopControllerName = "/workshops";
const configurationControllerName = "/configuration";
const regionControllerName = "/regions";
const customersControllerName = "/customers";
const lookupControllerName = "/lookup";
const userControllerName = "/users";
const serviceRequestControllerName = "/servicerequests";
const serviceCardControllerName = "/servicecard";
const vehicleControllerName = "/vehicles";
const otpControllerName = "/otp";
const notificationsControllerName = "/notification";
const docusignControllerName = "/docusign";
const importControllerName = "/Import";
const permissionControllerName = "/permission";
const agentControllerName = "/ambassador";
const orderControllerName = "/order";
const inventoryControllerName = "/Inventory";
const systemControllerName = "/system";
const recoveryTrucksControllerName = "/truckdriver";
const journeyControllerName = "/journey";
const paymentController = "/paymentgateway";
const walletControllerName = "/Wallet";
const payoutControllerName = "/Payout";

export const SERVICE_CONFIG_URLS = {
    AUTH: {
        LOGIN: `${authControllerName}/login`,
        SIGN_UP: `${authControllerName}/create`,
        FORGET_PASSWORD: `${authControllerName}/forgetpassword`,
        EMAIL_VERIFICATION: `${authControllerName}/verifyemail`,
        RESET_PASSWORD: `${authControllerName}/updatepassword`,
        REMOVE_SUSPENSION: `${authControllerName}/removesuspension`,
        RESEND_VERIFICATION_EMAIL: `${authControllerName}/resendverificationemail`
    },
    REGISTRATION: {
        CONTROLLER_NAME: registrationControllerName,
        WORKSHOP: `${registrationControllerName}/workshops`,
        WORKSHOP_OTP: `${registrationControllerName}/workshop`,
        CUSTOMER: `${registrationControllerName}/customers`
    },
    WORKSHOP: {
        CONTROLLER_NAME: workshopControllerName,
        USERS: `${workshopControllerName}`,
        ADD_INVENTORY_PARTS: `${workshopControllerName}/inventory`,
        INVOICE: `${workshopControllerName}/invoices`,
        PREFERENCES: `/preferences`,
        PAYMENT_METHODS: `${workshopControllerName}/paymentmethods`,
        REVENUES: `${workshopControllerName}/{id}/revenues`,
        JOURNEY_PAYOUTS: `${workshopControllerName}/{id}/journeypayouts`,
        DISPERSALS: `${workshopControllerName}/{id}/dispersals`,
        REGIONS: `${workshopControllerName}/regions`
    },
    CONFIGURATION: {
        BRANDS: `${configurationControllerName}/brands`,
        SERVICES: `${configurationControllerName}/services`,
        EQUIPMENTS: `${configurationControllerName}/equipments`,
        WORKSHOP_EQUIPMENTS: `${configurationControllerName}/workshopequipment`,
        VEHICLE_CATEGORIES: `${configurationControllerName}/vehicletypes`,
        INVOICES: `${configurationControllerName}/invoices`,
        CUSTOMERS: `${configurationControllerName}/customers`,
        REFERRAL_CODE: `${configurationControllerName}/referralcodes`
    },
    REGION: {
        CONTROLLER_NAME: `${regionControllerName}`
    },
    CUSTOMERS: {
        CONTROLLER_NAME: `${customersControllerName}`
    },
    LOOKUP: {
        CUSTOMER: `${lookupControllerName}/customers`,
        MANUFACTURER: `${lookupControllerName}/manufacturer`,
        CATALOG: `${lookupControllerName}/catalog`,
        SUPPLIER: `${lookupControllerName}/supplier`,
        SCAN_QR_LINK: `${lookupControllerName}/link`,
        VEHICLE_INFO: `${lookupControllerName}/vehicle`,
        INVENTORY_PART: `${lookupControllerName}/groupedinventory`,
        COMPANY: `${lookupControllerName}/company`,
        AMBASSADOR: `${lookupControllerName}/ambassador`,
        PAYOUT: `${lookupControllerName}/payouts`
    },
    USER: {
        CONTROLLER_NAME: `${userControllerName}`,
        CUSTOMERS: `${userControllerName}/customers`,
        PREFERENCES: `/preferences`
    },
    SERVICE_REQUEST: {
        SERVICES: serviceRequestControllerName,
        CONTROLLER_NAME: `${serviceRequestControllerName}`,
        ADD_JOB_CARD: `${serviceRequestControllerName}/servicerequests`
    },
    VEHICLE: {
        CONTROLLER_NAME: vehicleControllerName,
        QR_CODE: vehicleControllerName,
        VEHICLES: `${vehicleControllerName}`
    },
    SERVICE_REQUEST: {
        CONTROLLER_NAME: `${serviceRequestControllerName}`,
        QR_CODE: vehicleControllerName,
        VEHICLES: `${vehicleControllerName}`
    },
    SERVICE_REQUEST: {
        CONTROLLER_NAME: `${serviceRequestControllerName}`,
        GET_TICKETS: `${serviceRequestControllerName}/Tickets`
    },
    SERVICE_CARD: {
        CONTROLLER_NAME: `${serviceCardControllerName}`
    },
    OTP: {
        VERIFY_CUSTOMER: `${otpControllerName}/account`,
        VEHICLE_VERIFY: `${otpControllerName}/vehicle`,
        RESEND_CUSTOMER: `${otpControllerName}/regenerate/account`,
        RESEND_VEHICLE: `${otpControllerName}/regenerate/vehicle`,
        VERIFY_WORKSHOP_OTP: `${otpControllerName}/workshopadmin/verify`,
        RESEND_WORKSHOP_OTP: `${otpControllerName}/regenerate/workshopadmin`
    },
    NOTIFICATIONS: {
        CONTROLLER_NAME: notificationsControllerName,
        CHAT_NOTIFICATION: `${notificationsControllerName}/chatnotifications`
    },
    DOCUSIGN: {
        CONTROLLER_NAME: docusignControllerName
    },
    IMPORT: {
        CONTROLLER_NAME: importControllerName,
        CUSTOMERS: `${importControllerName}/customers`
    },
    PERMISSION: {
        CONTROLLER_NAME: permissionControllerName,
        UPDATE_PERMISSION: `${permissionControllerName}/update`
    },
    AGENT: {
        CONTROLLER_NAME: `${agentControllerName}`,
        GET_PROFILE: `${agentControllerName}/{id}`,
        UPLOAD: `${agentControllerName}/{id}/upload`,
        HIRARCHY: `${agentControllerName}/familytree/{id}`,
        GET_REGIONS: `${agentControllerName}/{id}/regions`,
        MAX_CLEARANCE: `${agentControllerName}/maxclearance`,
        GET_AFFILIATES: `${agentControllerName}/workshops/{id}/affiliates/{type}`,
        GET_EARNING: `${agentControllerName}/earnings/{id}/{type}?regionId={regionId}`,
        GET_AGENT_REGION: `${agentControllerName}/regions`,
        GET_AGENT_BANK_DETAIL: `${agentControllerName}/{id}/bankdetails`,
        UPADTE_AGENT_BANK_DETAIL: `${agentControllerName}/{id}/bankdetail`,
        DISPERSALS: `${agentControllerName}/{id}/regions/{regionId}/dispersals`
    },
    ORDER: {
        CONTROLLER_NAME: `${orderControllerName}`
    },
    INVENTORY: {
        CONTROLLER_NAME: `${inventoryControllerName}`
    },
    SYSTEM: {
        CONTROLLER_NAME: `${systemControllerName}`
    },
    RECOVERY_TRUCKS: {
        CONTROLLER_NAME: `${recoveryTrucksControllerName}`,
        REVENUES: `${recoveryTrucksControllerName}/{id}/revenues`,
        REGIONS: `${recoveryTrucksControllerName}/regions`,
        TRUCK_ACTIVITY: `${recoveryTrucksControllerName}/activity`
    },
    JOURNEY: {
        CONTROLLER_NAME: journeyControllerName
    },
    PAYMENT: {
        WORKSHP_SETUP_INTENT: "/workshop/setupintent",
        CONTROLLER_NAME: `${paymentController}`,
        STATUS_CHECK: `${paymentController}/{serviceId}/status`,
        PAY: `${paymentController}/{pmId}/{serviceCardId}/authorize`,
        JOURNEY_PAY: `${paymentController}/{pmId}/journey/{journeyId}/authorize`
    },
    WALLET: {
        WALLET_DETAIL: walletControllerName
    },
    CHAT: {
        UPLOADS: `${process.env.REACT_APP_API_VERSION}/rooms.upload/{rid}`,
        READ: `${process.env.REACT_APP_API_VERSION}/subscriptions.read`
    },
    PAYOUT: {
        CONTROLLER_NAME: payoutControllerName,
        GET_BALANCE: `${payoutControllerName}/balance`,
        UPLOAD_PAYOUT_ATTACH: `${payoutControllerName}/{id}/upload`,
        GET_PAYOUT: `${payoutControllerName}/{id}`,
        REVENUE_LISING: `${payoutControllerName}/revenues`
    }
};

export const multiFormDataHeader = {
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "multipart/form-data"
    }
};
