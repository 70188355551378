import ApiService from "../util/api.service";
import { SERVICE_CONFIG_URLS } from "./config";

const LookupService = {
    getCustomers,
    getManufacturers,
    getCatalogs,
    getSupplier,
    getScanQRLink,
    getVehicleInfo,
    getInventoryParts,
    getCompanies,
    getAddressFromCordinates,
    getAmbassadors,
    getPayouts
};

async function getAmbassadors(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.LOOKUP.AMBASSADOR, payload);
    return response;
}

async function getCustomers(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.LOOKUP.CUSTOMER, payload);
    return response;
}

async function getManufacturers(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.LOOKUP.MANUFACTURER, payload);
    return response;
}

async function getCatalogs(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.LOOKUP.CATALOG, payload);
    return response;
}

async function getSupplier(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.LOOKUP.SUPPLIER, payload);
    return response;
}

async function getScanQRLink() {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.LOOKUP.SCAN_QR_LINK, null, {
        responseType: "arraybuffer"
    });
    return response;
}

async function getVehicleInfo(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.LOOKUP.VEHICLE_INFO, payload);
    return response;
}

async function getInventoryParts(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.LOOKUP.INVENTORY_PART, payload);
    return response;
}

async function getCompanies(payload) {
    const response = await ApiService.post(`${SERVICE_CONFIG_URLS.LOOKUP.COMPANY}`, payload);
    return response;
}

async function getPayouts(payload) {
    const response = await ApiService.post(`${SERVICE_CONFIG_URLS.LOOKUP.PAYOUT}`, payload);
    return response;
}

function getAddressFromCordinates(latLng) {
    const promise = new Promise((resolve, reject) => {
        let geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
            {
                latLng: latLng
            },
            (results, status) => {
                if (status == window.google.maps.GeocoderStatus.OK) {
                    resolve({ ok: true, data: results });
                } else {
                    reject({ ok: false, data: status });
                }
            }
        );
    });

    return promise;
}

export default LookupService;
