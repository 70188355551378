import React from "react";
import { Empty as AntdEmpty } from "antd";
import { useTranslation } from "react-i18next";

function Empty({ description, ...props }) {
    const { t } = useTranslation("workshopDashboard");

    return <AntdEmpty description={description || t("workshopDashboard:emptyText")} {...props} />;
}

Empty.propTypes = AntdEmpty.propTypes;

export default Empty;
