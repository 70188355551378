import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton, Button, Empty as AntdEmpty } from "antd";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import RegionService from "../../services/region.service";
import notificationService from "../../util/notification.service";
import translationService from "../../util/translation.service";
import { contentType, privacyPolicy } from "../../constants/constant";
import PrivacyModal from "../../components/Modal";
import Empty from "../../components/empty";
import privacyImage from "../../assets/images/Privacy-Policy.png";
import AppLocale from "../../languageProvider";
import "./index.less";

function UserTermsConditions(props) {
    let { id, culture } = useParams();
    const workshopTranslation = "workshopOnboardingWizard";
    const { t } = useTranslation([workshopTranslation]);

    const [isTermsCallInProgress, setIsTermsCallInProgress] = useState(false);
    const [termsConditions, setTermsConditions] = useState(null);

    const [isPolicyCallInProgress, setIsPolicyCallInProgress] = useState(false);
    const [policy, setPolicy] = useState(null);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);

    useEffect(() => {
        async function load() {
            setIsTermsCallInProgress(true);
            setIsPolicyCallInProgress(true);
            Promise.all([
                RegionService.getContractInfo(contentType, {}, id),
                RegionService.getContractInfo(privacyPolicy, {}, id)
            ]).then(([terms, privacy]) => {
                if (terms.ok && privacy.ok) {
                    setTermsConditions(terms.data);
                    setPolicy(privacy.data);
                } else if ((!terms.ok && privacy.ok) || (terms.ok && !privacy.ok)) {
                    setTermsConditions(terms.ok ? terms.data : null);
                    setPolicy(privacy.ok ? privacy.data : null);
                } else {
                    notificationService.error(
                        !privacy.ok ? privacy.response.message : terms.response.message
                    );
                }
                setIsTermsCallInProgress(false);
                setIsPolicyCallInProgress(false);
            });
        }
        if (AppLocale.find((x) => x.id === culture)) {
            translationService.updateLocale(culture);
            load();
        }
    }, []);

    function createMarkup() {
        return { __html: termsConditions };
    }

    function createPrivacyMarkup() {
        return { __html: policy };
    }
    return (
        <>
            <PrivacyModal
                visible={showPrivacyModal}
                src={privacyImage}
                handleCancel={() => setShowPrivacyModal(!showPrivacyModal)}
                title={t(`${workshopTranslation}:agreementPrivacyPolicy`)}
                fixedHeight
            >
                {policy ? (
                    <div className="mtb-50" dangerouslySetInnerHTML={createPrivacyMarkup()}></div>
                ) : (
                    <Empty image={AntdEmpty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </PrivacyModal>

            {/* <Row justify="end" className="signup-gx-language">
                <Popover
                    overlayClassName="gx-popover-horizantal"
                    placement="bottomRight"
                    content={languageMenu()}
                    trigger="click"
                    defaultVisible={isLocaleOpen == null ? true : false}
                >
                    <span
                        className="gx-pointer gx-flex-row gx-align-items-center"
                        style={{ color: "white" }}
                    >
                        <span className="gx-pl-2 gx-language-name" style={{ color: "white" }}>
                            {currentLocale.lang.name}
                        </span>
                        <i className="icon icon-chevron-down gx-pl-2" />
                    </span>
                </Popover>
            </Row> */}

            <Row justify="center">
                <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <div className="wizard-heading contract-review-icon-center">
                        <h1>{t(`${workshopTranslation}:termsConditions`)}</h1>
                    </div>
                </Col>
                <Col sm={24} xs={24} md={24} lg={23} xl={23} xxl={23}>
                    {isTermsCallInProgress ? (
                        <Skeleton active />
                    ) : termsConditions ? (
                        <div
                            dangerouslySetInnerHTML={createMarkup()}
                            className="text-color-white scrollable-content scrollable-privacy"
                            id="scrollable-privacy"
                        />
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="text-color-white" />
                    )}
                </Col>

                <Col
                    sm={20}
                    xs={20}
                    md={20}
                    lg={8}
                    xl={8}
                    xxl={8}
                    className="submit-btn-center mtb-30"
                >
                    <Button
                        type="primary"
                        className="width-100 privacy-policy-btn"
                        size="large"
                        onClick={() => setShowPrivacyModal(!showPrivacyModal)}
                    >
                        {t(`${workshopTranslation}:agreementPrivacyPolicy`)}
                    </Button>
                </Col>
            </Row>

            {/* <Row align="middle" justify="center">
                <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Row
                        gutter={[25, 15]}
                        type="flex"
                        justify="center"
                        align="center"
                        className="margin-0"
                    >
                        <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="wizard-heading contract-review-icon-center">
                                <h1>{t(`${workshopTranslation}:termsConditions`)}</h1>
                            </div>
                        </Col>
                    </Row>

                    <Row align="middle" justify="center">
                        <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                            {isTermsCallInProgress ? (
                                <Skeleton active />
                            ) : !isTermsCallInProgress && termsConditions ? (
                                <>
                                    <Row className="scrollable-content">
                                        <Col sm={24} xs={24} md={24} lg={24} xl={24} xxl={24}>
                                            <div
                                                dangerouslySetInnerHTML={createMarkup()}
                                                className="text-color-white"
                                            ></div>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    className="text-color-white"
                                />
                            )}
                        </Col>
                    </Row>
                </Col>

                <Col
                    sm={24}
                    xs={24}
                    md={24}
                    lg={8}
                    xl={8}
                    xxl={8}
                    className="submit-btn-center mtb-30"
                >
                    <Button
                        type="primary"
                        className="width-100 privacy-policy-btn"
                        size="large"
                        onClick={() => setShowPrivacyModal(!showPrivacyModal)}
                    >
                        {t(`${workshopTranslation}:agreementPrivacyPolicy`)}
                    </Button>
                </Col>
            </Row> */}
        </>
    );
}

export default UserTermsConditions;
